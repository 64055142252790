import React, {useEffect, useState} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp, IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {images, clipboard, build, checkmarkDone} from 'ionicons/icons';
import {t} from "./utils/Translation"

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import "./css/General.css";

/* Pages */
import RequestLog from "./pages/RequestLog";
import Requests from './pages/Requests';
import Dashboard from './pages/Dashboard';
import PrijavaKvaraPage from './pages/PrijavaKvara';
import ZadaciPage from './pages/Zadaci';
import Details from './pages/Details';
import Login from './pages/Login';
import EquipmentCard from "./pages/EquipmentCard";
import EquipmentCardsList from "./pages/EquipmentCardsList";
import MttrReport from "./pages/Reports/MttrReport";
import MttRecoveryReport from "./pages/Reports/MttRecoveryReport";
import TotalResolvedReport from "./pages/Reports/TotalResolvedReport";
import ResolvedTimeReport from "./pages/Reports/ResolvedTimeReport";
import WorkingHoursReport from "./pages/Reports/WorkingHoursReport";
import Pictures from "./pages/Pictures";
import RequestEdit from "./pages/RequestEdit"

import {firebaseMessaging} from "./firebaseConfig";
import myFetch from "./utils/MyFetch";
import Storage from "./utils/Storage";
import {createStore} from "redux";
import reducers from "./reducers";
import { connect } from "react-redux";
import ProfilePage from "./pages/ProfilePage";

const App: React.FC = (props:any) => {

  const reduxStore = createStore(reducers);
  let authed = localStorage.getItem("jwt") ? 1 : 0;
  const [notification, setNotification] = useState({display:false, title:"", body:""});
  const [zadaciNumber, setZadaciNumber] = useState(0);
  const [reloadZadaciNo, setReloadZadaciNo] = useState(false);

  const handleNotification = (notificationObject: any) => {
    setNotification(notificationObject)
  }

  if (authed === 1) {
    firebaseMessaging(handleNotification)
  }

  useEffect(() => {
    const userId = Storage.get("user_id")
    if (userId !== null && userId !== undefined) {
      myFetch(process.env.REACT_APP_API_URL + "/request-responsibles/count-active/"+userId)
          .then((data:any) => {
            setZadaciNumber(data)
          })
          .catch((alert) => {console.log(alert)});
    }
  }, [reloadZadaciNo])

  const getMojiZadaciNumber = () => {
    if (zadaciNumber > 0) {
      return <IonBadge color="danger">{zadaciNumber}</IonBadge>
    }
    return '';
  }

  return (
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route
                  path="/zahtevi/:status?"
                  exact
                  render = { () => {return authed === 1 ? <Requests/> : <Login />;} }
              />
              <Route
                  path="/home"
                  exact
                  render = { (props) => {return authed === 1 ? <Dashboard {...props} /> : <Login {...props} />;} }
              />
              <Route
                  path="/requests/details/:id"
                  exact
                  render = { (props) => {return authed === 1 ? <Details setReloadZadaciNo={setReloadZadaciNo} reloadZadaciNo={reloadZadaciNo} /> : <Login />;} }
              />
              <Route
                  path="/requests/edit/:id"
                  exact
                  render = { (props) => {return authed === 1 ? <RequestEdit {...props} /> : <Login />;} }
              />
              <Route
                  path="/pictures/:id"
                  exact
                  render = { (props) => {return authed === 1 ? <Pictures {...props} /> : <Login />;} }
              />
              <Route
                  path="/prijava-kvara"
                  exact
                  render = { (props) => {return authed === 1 ? <PrijavaKvaraPage {...props} /> : <Login />;} }
              />
              <Route
                  path="/zadaci"
                  exact
                  render = { () => {return authed === 1 ? <ZadaciPage /> : <Login />;} }
              />
              <Route
                  path="/profile"
                  exact
                  render = { (props) => {return authed === 1 ? <ProfilePage {...props} /> : <Login />;} }
              />
              <Route
                  path="/login"
                  render = {(props) => <Login {...props} />}
              />
              <Route
                  path="/"
                  render={() => <Redirect to="/home"/>}
                  exact={true}
              />
              <Route
                  path={"/requests/details/:id/log"}
                  render = { (props) => {return authed === 1 ? <RequestLog {...props} /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/equipment-card/:id"}
                  render = { (props) => {return authed === 1 ? <EquipmentCard /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/equipment-card-list"}
                  render = { (props) => {return authed === 1 ? <EquipmentCardsList {...props} /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/reports/mttr"}
                  render = { (props) => {return authed === 1 ? <MttrReport {...props} /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/reports/mttrecovery"}
                  render = { (props) => {return authed === 1 ? <MttRecoveryReport {...props} /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/reports/total-resolved"}
                  render = { (props) => {return authed === 1 ? <TotalResolvedReport {...props} /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/reports/resolve-time"}
                  render = { (props) => {return authed === 1 ? <ResolvedTimeReport {...props} /> : <Login />;} }
                  exact={true}
              />
              <Route
                  path={"/reports/working-hours"}
                  render = { (props) => {return authed === 1 ? <WorkingHoursReport {...props} /> : <Login />;} }
                  exact={true}
              />
            </IonRouterOutlet>
            <IonTabBar slot="bottom" color={"primary"}>
              <IonTabButton tab="zahtevi" href="/zahtevi">
                <IonIcon icon={clipboard}/>
                <IonLabel>{t("Lista zahteva")}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="zadaci" href="/zadaci">
                <IonIcon icon={checkmarkDone}/>
                {getMojiZadaciNumber()}
                <IonLabel>{t("Moji zadaci")}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="dashboard" href="/home">
                <IonIcon icon={images}/>
                <IonLabel>{t("Dashboard")}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab3" href="/prijava-kvara">
                <IonIcon icon={build}/>
                <IonLabel>{t("Prijava kvara")}</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>

        <IonToast
            isOpen={notification.display}
            onDidDismiss={() => setNotification({display: false, title:"", body:""})}
            message={notification.title + ": " + notification.body}
            position="top"
            buttons={[
              {
                text: 'OK',
                role: 'cancel',
                handler: () => {
                  // console.log('Ok clicked'); // Here comes handler if we want some action after user
                  // click on OK on Toast element
                  setReloadZadaciNo(!zadaciNumber)
                  window.location.href = '/zadaci'
                }
              }
            ]}
        />
      </IonApp>
  )
};

// Receives all data from store and filter only states we care about in this component
const mapStateToProps = (state:any) => {
  // key: loggedInUser is prop
  // value: state.loggedInUser is value returned from redux store we care about in this component
  return {
    loggedInUser: state.loggedInUser,
    notificationsNo: state.notificationsNo
  };
};

export default connect(mapStateToProps)(App);
