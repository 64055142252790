// Action creators. Allways returns redux action with type and payload properties

import IReduxAction from "../interfaces/IReduxAction";

export const loginUser = (user: number): IReduxAction => {
    return {
        type: 'USER_LOGGED_IN',
        payload: user
    }
};

export const logoutUser = (user: number): IReduxAction => {
    return {
        type: 'USER_LOGGED_OUT',
        payload: user
    }
}

export const setNotificationsNo = (value: number): IReduxAction => {
    return {
        type: 'SET_NOTIFICATIONS_NO',
        payload: value
    }
};