import React, {useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLoading,
    IonAlert
} from '@ionic/react';
import myFetch from "../utils/MyFetch";
import {useParams} from "react-router";
import IRequestFile from "../interfaces/IRequestFile";
import IZahtev from "../interfaces/IZahtev";
import IAlert from "../interfaces/IAlert";
import {t} from "../utils/Translation"
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

const Pictures = (props: any) => {

    let {id} = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [zahtev, setZahtev] = useState<IZahtev>({
        id: 0,
        company_id: 0,
        process_id: 0,
        user_id: 0,
        ip_v4: '',
        title: '',
        description: '',
        current_state: 0,
        status: '',
        priority: '',
        can_take: false,
        created_at: '',
        updated_at: '',
        links: {
            self: {rel: '', url: ''},
            user: {rel: '', url: ''},
            current_state: {rel: '', url: ''},
            production_line: {rel: '', url: ''},
            production_line_machine: {rel: '', url: ''},
            production_line_part: {rel: '', url: ''},
            responsible_group: {rel: '', url: ''},
            "request-actions": {rel: '', url: ''},
            "executed-actions": {rel: '', url: ''},
            transitions: {rel: '', url: ''}
        },
        request_data: {
            data: {
                production_line_id: 0,
                responsible_group_id: 0,
            }
        },
        request_images: {
            data: []
        }
    });

    useEffect(() => {
        setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/requests/"+id+"?include=request_images")
            .then(
                (result:any) => {
                    if (result) {
                        setZahtev(result);
                        setShowLoading(false);
                    } else {
                        // Here comes error generated from api. Response is received but error happend on API
                        setAlert({showAlert: true, message: result.error});
                    }
                },
            )
            .catch((data) => {setAlert({showAlert:true, message:data.message})});
    }, [id]);

    const images: any = zahtev.request_images?.data.map((image: IRequestFile, index) => {
        return {
            original: process.env.REACT_APP_API_URL + "/images/" + image.filename,
            thumbnail: process.env.REACT_APP_API_URL + "/images/" + image.filename
        }
    })

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Slike</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <ImageGallery items={images} />;

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    )
}

export default Pictures;