import React, {useState} from 'react';
import {t} from "../utils/Translation";

import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonRouterOutlet,
    IonIcon,
    IonLabel
} from '@ionic/react';
import {clipboardOutline, power, desktopOutline, analytics, caretDown, caretForward, personCircleOutline, buildOutline} from 'ionicons/icons';
import Storage from "../utils/Storage";
import '../css/MainMenu.css';
import myFetch from "../utils/MyFetch";

const MainMenu: React.FC = () => {

    const [showReportsSubmenu, setShowReportsSubmenu] = useState(true);

    const openReportsSubmenu = () => {
        setShowReportsSubmenu(!showReportsSubmenu)
    }

    const logout = () => {
        myFetch(process.env.REACT_APP_API_URL + "/auth/logout", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({id: Storage.get('user_id')})
        })
        .then(() => {
            Storage.remove('jwt');
            Storage.remove('user_id');
            window.location.href = '/login'
        })
        .catch((reason) => console.log(reason))
    }

    return (
        <>
            <IonMenu side="start" menuId="first" contentId="mainMenu">
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>{t("Glavni meni")}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent id="mainMenu">
                    <IonList class="clickable">
                        <IonItem key="1" routerLink="/home" routerDirection="forward" detail={true} >
                            <IonIcon icon={desktopOutline} slot="start" />
                            <IonLabel>{t("Dashboard")}</IonLabel>
                        </IonItem>
                        <IonItem key="2" routerLink="/zahtevi" routerDirection="forward" detail={true} >
                            <IonIcon icon={clipboardOutline} slot="start" />
                            <IonLabel>{t("Zahtevi")}</IonLabel>
                        </IonItem>
                        <IonItem key="3" routerDirection="forward" onClick={() => openReportsSubmenu()}>
                            <IonIcon icon={analytics} slot="start" />
                            <IonLabel>{t("Izveštaji")}</IonLabel>
                            <IonIcon icon={showReportsSubmenu ? caretForward : caretDown} slot="end" />
                        </IonItem>
                        <IonItem hidden={showReportsSubmenu} key="6" routerLink="/reports/mttr" routerDirection="forward" detail={true} class="sub-menu">
                            <IonIcon slot="start" />
                            <IonLabel>MTTRepair</IonLabel>
                        </IonItem>
                        <IonItem hidden={showReportsSubmenu} key="7" routerLink="/reports/mttrecovery" routerDirection="forward" detail={true} class="sub-menu">
                            <IonIcon slot="start" />
                            <IonLabel>MttRecovery</IonLabel>
                        </IonItem>
                        <IonItem hidden={showReportsSubmenu} key="8" routerLink="/reports/total-resolved" routerDirection="forward" detail={true} class="sub-menu">
                            <IonIcon slot="start" />
                            <IonLabel>{t("Ukupno rešenih")}</IonLabel>
                        </IonItem>
                        <IonItem hidden={showReportsSubmenu} key="9" routerLink="/reports/resolve-time" routerDirection="forward" detail={true} class="sub-menu">
                            <IonIcon slot="start" />
                            <IonLabel>{t("Vreme rešavanja")}</IonLabel>
                        </IonItem>
                        <IonItem hidden={showReportsSubmenu} key="10" routerLink="/reports/working-hours" routerDirection="forward" detail={true} class="sub-menu">
                            <IonIcon slot="start" />
                            <IonLabel>{t("Radni sati")}</IonLabel>
                        </IonItem>
                        <IonItem key="12" routerLink="/equipment-card-list" routerDirection="forward" detail={true}>
                            <IonIcon icon={buildOutline} slot="start" />
                            <IonLabel>{t("Kartoni opreme")}</IonLabel>
                        </IonItem>
                        <IonItem key="11" routerLink="/profile" routerDirection="forward" detail={true}>
                            <IonIcon icon={personCircleOutline} slot="start" />
                            <IonLabel>{t("Moji podaci")}</IonLabel>
                        </IonItem>
                        <IonItem key="4" onClick={logout} detail={true}>
                            <IonIcon icon={power} slot="start" />
                            <IonLabel>{t("Logout")}</IonLabel>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>
            <IonRouterOutlet></IonRouterOutlet>
        </>
    );
}

export default MainMenu;