import React, {useEffect, useState} from "react";
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonModal,
    IonLabel,
    IonTextarea,
    IonItem,
    IonAlert, IonLoading, IonSelect, IonSelectOption
} from "@ionic/react";
import ITransition from "../interfaces/ITransition";
import ModalInsertPartData from "./ModalInsertPartData";
import IInstalledPart from "../interfaces/IInstalledPart";
import IAlert from "../interfaces/IAlert";
import myFetch from "../utils/MyFetch";
import IGroup from "../interfaces/IGroup";
import IZahtev from "../interfaces/IZahtev";
import IProductionLinePart from "../interfaces/IProductionLinePart";
import Storage from "../utils/Storage";
import {t} from "../utils/Translation"

type ModalProps = {
    showModal: {showModal:boolean, transition: ITransition},
    setShowModal: any,
    dataForSending: any,
    setDataForSending: any,
    handleOnModalClose: any,
    zahtev: IZahtev,
    groups: IGroup[],
    productionLineParts: IProductionLinePart[][],
    setProductionLineParts: any,
}

const ModalInsertCommentAndInstalledParts = ({ showModal, setShowModal, dataForSending, setDataForSending, handleOnModalClose, zahtev, groups, productionLineParts, setProductionLineParts }: ModalProps) => {

    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showModalInsertPartData, setShowModalInsertPartData] = useState(false);
    const [tipKvara, setTipKvara] = useState();
    const [installedParts, setInstalledParts] = useState<IInstalledPart[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [choosenPartLevel, setChoosenPartLevel] = useState(0);

    useEffect(() => {
        setTipKvara(zahtev.request_data.data.responsible_group_id)
    }, [zahtev])

    const handleInput = (value:object) => {
        setDataForSending({...dataForSending, ...value, resolve_user_id: Storage.get("user_id")});
    };

    const addPartDataToArray = (partData: IInstalledPart) => {
        setInstalledParts([...installedParts, partData])
        setDataForSending({...dataForSending, installedParts: [...installedParts, partData]})
    }

    let installedPartsItems = installedParts.map((installedPart, index) => {
        return (
            <IonItem key={index}>
                <label>{installedPart.name},&nbsp;</label>
                <p> šifra dela: {installedPart.part_code}, {installedPart.quantity} {installedPart.jm}</p>
            </IonItem>
        )
    })

    const onSubmit = () => {
        if (dataForSending.fix_description && dataForSending.fix_description !== '') {
            setShowModal({showModal: false, transition: showModal.transition})
            handleOnModalClose(showModal.transition)
        } else {
            setAlert({showAlert: true, message: t("Komentar je obavezan!")})
        }
    }

    const getDataForProductionLine = (productionLineId: number, slicedProductionLineparts: IProductionLinePart[][]) => {
        myFetch(process.env.REACT_APP_API_URL + "/production-lines?parent_id="+productionLineId)
            .then((res:any) => {
                setProductionLineParts([...productionLineParts, res]);
                // if (slicedProductionLineparts.length > 0) {
                //     setProductionLineParts([...slicedProductionLineparts, res]);
                // } else {
                //     setProductionLineParts([...productionLineParts, res]);
                // }
            })
            .catch((res) => {setAlert({showAlert:true, message:res.message, header: "Greska"})});
    };

    const onMachineSelected = (value: string) => {
        handleInput({production_line_id: parseInt(value)});
        setChoosenPartLevel(choosenPartLevel + 1)
        // Izbrisati iz arraya komponenti sve komponente ispod

        // Pre nego sto uzme podatke, pronaci ovaj id, u productionLineParts i izbrisati sve ispod
        let needle = 0;
        let selected: IProductionLinePart = {
            id: 0,
            machine_id: null,
            name: '',
            type: '',
            description: '',
            children_count: 0,
            parent_id: 0,
            inventory_number: null,
            created_at: '',
            updated_at: '',
            links: {
                self: { rel: '', url: ''},
                parent: { rel: '', url: ''}
            }
        };
        for (let i=0; i < productionLineParts.length; i++) {
            productionLineParts[i].forEach((part: IProductionLinePart) => {
                if (part.id === parseInt(value)) {
                    needle = i;
                    selected = part;
                }
            });
        }

        // pogledati da li selectovani part ima childrene, ako ima uzimamo ih
        if (selected.children_count && selected.children_count > 0) {
            getDataForProductionLine(parseInt(value), productionLineParts.slice(0, needle + 1));
        }

        // moramo da resetujemo i level na 1 ukoliko je zona izabrana ili promenjena
        if (selected.type === "set") {
            setChoosenPartLevel(3);
        } else if (selected.type === "subset") {
            setChoosenPartLevel(4);
        }
    };

    const onCancel = () => {
        setShowModal({showModal: false, transition: showModal.transition})
        setDataForSending({})
    }

    let dropDownsArray = productionLineParts.map((value: IProductionLinePart[], index) => {
        let partType = value[value.length - 1];
        if (partType) {
            let label:string = "";
            switch (partType.type) {
                case "zone":
                    label = t("Proizvodna linija");
                    break;
                case "machine":
                    label = t("Mašina");
                    break;
                case "set":
                    label = t("Sklop");
                    break;
                case "subset":
                    label = t("Podsklop");
                    break;
                case "part":
                    label = t("Deo");
                    break;
            }
            return (
                <Machine key={index} label={label} parts={value} onMachineSelected={onMachineSelected}/>
            );
        }
        return false;
    });

    return(
        <IonModal isOpen={showModal.showModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("Unos podataka o popravci")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite opis popravke")}...</IonLabel>
                    <IonTextarea
                        value={dataForSending.fix_description}
                        onIonChange={e => handleInput({fix_description:e.detail.value!})}
                        rows={6}
                    >
                    </IonTextarea>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Tip kvara")}</IonLabel>
                    <IonSelect
                        value={parseInt(tipKvara)}
                        cancelText={t("Otkaži")}
                        onIonChange={e => {
                            setTipKvara((e.target as HTMLInputElement).value)
                            handleInput({forward_to_group_id:(e.target as HTMLInputElement).value})
                        }}
                    >
                        {groups.map((object, index) => {
                            return (
                                <IonSelectOption key={index} value={object.id}>
                                    {object.name}
                                </IonSelectOption>
                            );
                        })}
                    </IonSelect>
                </IonItem>

                {/*todo srediti ovo */}
                {/*{dropDownsArray}*/}

                {installedPartsItems}

                <div className={"action-buttons-cont-one-row"}>
                    <IonButton size={"large"} onClick={() => setShowModalInsertPartData(true)} color="success">{t("Dodaj deo")}</IonButton>
                    <IonButton size={"large"} onClick={() => {onSubmit()}}>{t("Pošalji")}</IonButton>
                    <IonButton size={"large"} color="light" onClick={() => onCancel()}>{t("Otkaži")}</IonButton>
                </div>

                <ModalInsertPartData
                    showModal={showModalInsertPartData}
                    setShowModal={setShowModalInsertPartData}
                    handleOnModalClose={addPartDataToArray}
                />

                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Upozorenje')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t('Učitavanje podataka')+'...'}
                    duration={10000}
                />
            </IonContent>
        </IonModal>
    );
}

export default ModalInsertCommentAndInstalledParts;


interface props {
    label: string,
    parts: IProductionLinePart[],
    onMachineSelected: (value: string) => void
}

const Machine: React.FC<props> = ({label, parts, onMachineSelected}) => {

    return(
        <IonItem>
            <IonLabel position="floating">{label}</IonLabel>
            <IonSelect cancelText={t("Otkaži")} onIonChange={(e) => {onMachineSelected((e.target as HTMLIonSelectElement).value)}}>
                {parts.map((object:IProductionLinePart) => {
                    return (
                        <IonSelectOption key={object.id} value={object.id}>
                            {object.name}
                        </IonSelectOption>
                    );
                })}
            </IonSelect>
        </IonItem>
    );
};