import {useState} from "react";
// import {useCamera} from '@ionic/react-hooks/camera';
import {base64FromPath} from '@ionic/react-hooks/filesystem';
// import {CameraDirection, CameraResultType, CameraSource} from "@capacitor/core";
import {IPhoto} from "../interfaces/IPhoto";
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
const { Camera } = Plugins;


export function usePhotoGallery() {
    // const { getPhoto } = useCamera();
    const [photos, setPhotos] = useState<IPhoto[]>([]);

    const takePhoto = async () => {
        const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
            quality: 50,
            allowEditing: false,
            webUseInput: true
        });
        const fileName = new Date().getTime() + '.jpeg';
        const newPhotos = [{
            filepath: fileName,
            webviewPath: cameraPhoto.webPath,
            base64: await base64FromPath(cameraPhoto.webPath!)
        }, ...photos];
        setPhotos(newPhotos)
    };

    return { photos, takePhoto };
}