import React, {useEffect, useState} from "react";
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonModal,
    IonLabel,
    IonTextarea,
    IonItem, IonAlert
} from "@ionic/react";
import ITransition from "../interfaces/ITransition";
import IAlert from "../interfaces/IAlert";
import {t} from "../utils/Translation"

type ModalProps = {
    showModal: {showModal:boolean, transition: ITransition, title?: string},
    setShowModal: any,
    dataForSending: any,
    setDataForSending: any,
    handleOnModalClose: any
}

const ModalInsertComment = ({showModal, setShowModal, dataForSending, setDataForSending, handleOnModalClose}: ModalProps) => {

    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [submitAlert, setSubmitAlert] = useState<IAlert>({showAlert:false, message: ""});

    const onSubmit = () => {
        if (dataForSending.comment && dataForSending.comment !== '') {
            if (showModal.title === 'Zatvaranje zahteva') {
                setSubmitAlert({showAlert:true, message: "Da li ste sigurni da želite da zatvorite nalog?"})
            } else {
                confirmSubmit()
            }
        } else {
            setAlert({showAlert: true, message: "Komentar je obavezan!"})
        }
    }

    const confirmSubmit = () => {
        setShowModal({showModal: false, transition: showModal.transition})
        handleOnModalClose(showModal.transition)
    }

    return(
        <IonModal isOpen={showModal.showModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{showModal.title ? showModal.title : t('Unos komentara')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite komentar")}...</IonLabel>
                    <IonTextarea
                        value={dataForSending.comment}
                        onIonChange={e => setDataForSending({...dataForSending, comment:e.detail.value!})}
                        rows={6}
                    >
                    </IonTextarea>
                </IonItem>
                <div className={"action-buttons-cont-one-row"}>
                    <IonButton size={"large"} onClick={() => onSubmit()}>{t("Pošalji")}</IonButton>
                    <IonButton size={"large"} color="light" onClick={() => setShowModal({showModal: false, transition: showModal.transition})}>{t("Otkaži")}</IonButton>
                </div>
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
                <IonAlert
                    isOpen={submitAlert.showAlert}
                    onDidDismiss={() => setSubmitAlert({showAlert: false, message: ""})}
                    header={''}
                    subHeader={''}
                    message={submitAlert.message}
                    buttons={[
                        {
                            text: t('Otkaži'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                setSubmitAlert({showAlert: false, message: ""})
                            }
                        },
                        {
                            text: 'OK',
                            handler: () => {
                                confirmSubmit()
                            }
                        }
                    ]}
                />
            </IonContent>
        </IonModal>
    );
}

export default ModalInsertComment;