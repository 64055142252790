import React, {useEffect, useState} from "react";
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonModal,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonItem, IonAlert, IonTextarea
} from "@ionic/react";
import ITransition from "../interfaces/ITransition";
import IGroup from "../interfaces/IGroup";
import myFetch from "../utils/MyFetch";
import IAlert from "../interfaces/IAlert";
import {t} from "../utils/Translation"

type ModalProps = {
    showModal: {showModal:boolean, transition: ITransition},
    setShowModal: any,
    dataForSending: any,
    setDataForSending: any,
    handleOnModalClose: any
}

const ModalSelectNewGroup = ({ showModal, setShowModal, dataForSending, setDataForSending, handleOnModalClose }: ModalProps) => {

    const [availableGroups, setAvailableGroups] = useState<IGroup[]>();
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});

    useEffect(() => {
        // setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/groups")
            .then(
                (result:any) => {
                    if (result) {
                        setAvailableGroups(result);
                    } else {
                        // Here comes error generated from api. Response is received but error happend on API
                        // setAlert({showAlert: true, message: result.error});
                    }
                },
            )
            .catch((data) => {console.log({showAlert:true, message:data.message})});
    }, []);

    let selectOptions;
    if (availableGroups && availableGroups.length > 0) {
        selectOptions = availableGroups.map((group, index) => {
            return (
                <IonSelectOption key={index} value={group.id}>
                    {group.name}
                </IonSelectOption>
            );
        })
    }

    const handleSelect = (value: number) => {
        setDataForSending({...dataForSending, forward_to_group_id: value})
    }

    const onSubmit = () => {
        if (dataForSending.comment && dataForSending.comment !== '' && dataForSending.forward_to_group_id && dataForSending.forward_to_group_id !== '') {
            setShowModal({showModal: false, transition: showModal.transition})
            // handleOnModalClose(showModal.transition) // <-- kada ovo komentujem onda nemam duple ikone?! nema logike...
        } else {
            setAlert({showAlert: true, message: t("Morate izabrati grupu kojoj prosleđujete zahtev i uneti komentar!")})
        }
    }


    return(
        <IonModal
            isOpen={showModal.showModal}
            onDidDismiss={()=>{
                if (Object.keys(dataForSending).length !== 0) {
                    handleOnModalClose(showModal.transition)
                }
            }
        }>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("Izaberite novu grupu")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel>{t("Odgovorna grupa")}:</IonLabel>
                    <IonSelect cancelText={t("Otkaži")} placeholder={t("Izaberite novu grupu")} onIonChange={(e) => {handleSelect((e.target as HTMLIonSelectElement).value)}}>
                        {selectOptions}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite komentar")}...</IonLabel>
                    <IonTextarea
                        value={dataForSending.comment}
                        onIonChange={e => setDataForSending({...dataForSending, comment:e.detail.value!})}
                        rows={6}
                    >
                    </IonTextarea>
                </IonItem>
                <div className={"action-buttons-cont-one-row"}>
                    <IonButton size={"large"} onClick={() => onSubmit()}>{t("Prosledi")}</IonButton>
                    <IonButton size={"large"} color="light" onClick={() => setShowModal({showModal: false, transition: showModal.transition})}>{t("Otkaži")}</IonButton>
                </div>
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Upozorenje')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonModal>
    );
}

export default ModalSelectNewGroup;