import React, {useState} from "react";
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonModal,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonItem, IonAlert
} from "@ionic/react";
import IResponsibleGroup from "../interfaces/IResponsibleGroup";
import ITransition from "../interfaces/ITransition";
import "../css/Modal.css"
import IAlert from "../interfaces/IAlert";
import {t} from "../utils/Translation"

type ModalProps = {
    showModal: {showModal:boolean, transition: ITransition},
    setShowModal: any,
    responsibleGroup: IResponsibleGroup,
    dataForSending: any,
    setDataForSending: any,
    handleOnModalClose: any
}

const ModalSelectTechnician = ({ showModal, setShowModal, responsibleGroup, dataForSending, setDataForSending, handleOnModalClose }: ModalProps) => {

    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [validated, setValidated] = useState(false)

    let selectOptions;
    if (responsibleGroup.users && responsibleGroup.users.length > 0) {
        selectOptions = responsibleGroup.users.map((user, index) => {

            return (
                <IonSelectOption disabled={!user.logged_in} class={user.busy ? "busy-user" : ''}  key={index} value={user.id}>
                    {user.first_name + ' ' + user.last_name}
                </IonSelectOption>
            );
        })
    }

    const handleSelect = (value: number) => {
        setDataForSending({forward_to_user_id: value})
        setValidated(true);
    }

    const onSubmit = () => {
        if (validated) {
            setShowModal({showModal: false, transition: showModal.transition})
            handleOnModalClose(showModal.transition)
        } else {
            setAlert({showAlert: true, message: t("Morate izabrati osobu kojoj prosleđujete zahtev!")})
        }
    }

    return(
        <IonModal
            isOpen={showModal.showModal}
            onDidDismiss={()=>{
                if (Object.keys(dataForSending).length !== 0) {

                }
            }
        }>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("Izaberite tehničara")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel>{t("Odgovorna osoba")}:</IonLabel>
                    <IonSelect cancelText={t("Otkaži")} placeholder={t("Izaberite člana grupe")} onIonChange={(e) => {handleSelect((e.target as HTMLIonSelectElement).value)}}>
                        {selectOptions}
                    </IonSelect>
                </IonItem>
                <div className={"action-buttons-cont-one-row"}>
                    <IonButton size={"large"} onClick={() => onSubmit()}>{t("Prosledi")}</IonButton>
                    <IonButton size={"large"} color="light" onClick={() => setShowModal({showModal: false, transition: showModal.transition})}>{t("Otkaži")}</IonButton>
                </div>
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Upozorenje')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonModal>
    );
}

export default ModalSelectTechnician;