import {useState} from "react";
import Storage from "../utils/Storage"

export function useGetLang() {
	const [lang, setLang] = useState(Storage.get("lang"));

	if (lang === "" || lang === undefined || lang === null) {
		setLang("en") // default
	}

	return { lang };
}