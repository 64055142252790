import moment from "moment";
import 'moment/locale/sr';

/**
 * Function which gets status string (eg: open, closed) and return string reporesenting color with which status
 * should be written
 * @param status
 * @returns string
 */
export const getColorforStatus = (status: string): string => {
	let color = 'dark';

	switch(status) {
		case 'open':
			color = "primary";
			break;
		case "in progress":
			color = "success";
			break;
		case "resolved":
			color = "success";
			break;
		case "waiting":
			color = "warning";
			break;
		case "denied":
			color = "medium";
			break;
		case "finished":
			color = "medium";
			break;
		case "error":
			color = "danger";
			break;
	}

	return color;
};

/**
 * Za dobijeni key prioriteta (low, normal, high) vraca naziv Nizak, Srednji, Visok
 * @param priorityKey
 */
export const getPriorityName = (priorityKey: string): string => {
	let name;

	switch(priorityKey) {
		case 'low':
			name = "Nizak";
			break;
		case "normal":
			name = "Srednji";
			break;
		case "high":
			name = "Visok";
			break;
		default:
			name = "Srednji"
	}

	return name;
};

/**
 * Returns datetime string formated to locale. Input string is mysql datetime string.
 * @param mySqlDateTime - mysql datetime string, eg. 2020-03-20 06:33:00
 * @returns 20.3.2020 06:33:00
 */
export const formatToLocalDateTime = (mySqlDateTime: string):string  => {
	if (mySqlDateTime.length < 1 ) {
		return '';
	}

	moment.locale(process.env.REACT_APP_LOCALE);
	let dateTime = moment(mySqlDateTime);
	return dateTime.format("D.M.YYYY. HH:mm:ss");
};

/**
 * Returns time portion of the string
 * @param mySqlDateTime - mysql datetime string, eg. 2020-03-20 06:33:00
 * @returns 06:33
 */
export const formatToLocalTime = (mySqlDateTime: string):string  => {
	if (mySqlDateTime.length < 1 ) {
		return '';
	}

	moment.locale(process.env.REACT_APP_LOCALE);
	let dateTime = moment(mySqlDateTime);
	return dateTime.format("HH:mm");
};

/**
 * Returns date portion of the string
 * @param mySqlDateTime - mysql datetime string, eg. 2020-03-20 06:33:00
 * @returns 21.01.2020
 */
export const formatToLocalDate = (mySqlDateTime: string):string  => {
	if (mySqlDateTime.length < 1 ) {
		return '';
	}

	moment.locale(process.env.REACT_APP_LOCALE);
	let dateTime = moment(mySqlDateTime);
	return dateTime.format("D.M.YYYY.");
};