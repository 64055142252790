import React, {useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLoading,
    IonAlert,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel, IonBadge
} from '@ionic/react';
import myFetch from "../utils/MyFetch";
import {useParams} from "react-router";
import IAlert from "../interfaces/IAlert";
import IZahtev from "../interfaces/IZahtev";
import {formatToLocalDateTime} from "../utils/ViewHelpers";
import UserPerformer from "../components/UserPerformer";
import IRequestComment from "../interfaces/IRequestComment";
import "../css/EquipmentCard.css"
import IMachine from "../interfaces/IMachine";
import {t} from "../utils/Translation"

const EquipmentCard = () => {

    let {id} = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [machine, setMachine] = useState<IMachine>({
        id: 0,
        name: "",
        description: "",
        inventory_number: '',
        serial_number: '',
        manufacturer: '',
        category_type: '',
        production_year: '',
        created_at: "",
        updated_at: "",
        links: {
            self: {rel:'', url: ''},
        }
    });
    const [requests, setRequests] = useState<IZahtev[]>([]);

    useEffect(() => {
        setShowLoading(true);
        Promise.all([
            myFetch(process.env.REACT_APP_API_URL + "/production-line-machines/"+id)
                .then((data:any) => {
                    setMachine(data)
                    setShowLoading(false);
                }),
            myFetch(process.env.REACT_APP_API_URL + "/requests?machine_id="+id+"&status=finished&include=installed_parts,installer,resolve_user,comments")
                .then((data:any) => {
                    setRequests(data)
                    setShowLoading(false);
                }),
        ]).catch((error) => {
            setShowLoading(false);
            setAlert({showAlert:true, message:error.message})
        });

    }, [id]);

    const displayOpis = (zahtev: IZahtev) => {
        if (zahtev.comments && zahtev.comments.data.length > 0) {
            let commentsArray: string[] = []
            zahtev.comments.data.forEach((comment: IRequestComment) => {
                if (comment.type === 'fix_description') {
                    commentsArray = [...commentsArray, comment.comment]
                }
            })
            return (
                <p className="comment-full">{t("Opis")}: {commentsArray.join("; ")}</p>
            )
        }
        return false;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{t("Karton opreme")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardSubtitle>{machine.category_type}</IonCardSubtitle>
                        <IonCardTitle>{machine.name}</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <p><strong>{t("Opis")}:</strong> {machine.description}</p>
                        <p><strong>{t("Inventarski broj")}:</strong> {machine.inventory_number}</p>
                        <p><strong>{t("Serijski broj")}:</strong> {machine.serial_number}</p>
                        <p><strong>{t("Proizvođač")}:</strong> {machine.manufacturer}</p>
                        <p><strong>{t("Godina proizvodnje")}:</strong> {machine.production_year}</p>
                    </IonCardContent>
                </IonCard>

                <IonList>
                    {requests.map((zahtev: IZahtev, index) => {
                        return (
                            <IonItem key={index} routerLink={"/requests/details/"+zahtev.id} routerDirection="forward" detail={true} >
                                <IonLabel>
                                    <h2>{zahtev.title}</h2>
                                    <p>{zahtev.description}</p>
                                    <p>{t("Datum kvara")}: {formatToLocalDateTime(zahtev.created_at)}</p>
                                    <p>{t("Datum popravke")}: {zahtev.request_data.data.resume_of_operations ? formatToLocalDateTime(zahtev.request_data.data.resume_of_operations) : ''}</p>
                                    <p>{t("Popravio")}: {zahtev.resolve_user ? zahtev.resolve_user?.data.first_name + ' ' + zahtev.resolve_user?.data.last_name : ''} </p>
                                    {displayOpis(zahtev)}
                                    {zahtev.installed_parts?.data.map((part, key) => {
                                        return (
                                            <section key={key}>
                                                <p>{t("Ugrađen deo")}: {part.name}, {part.quantity} {part.jm}</p>
                                                <UserPerformer user={part.user} date={part.created_at} />
                                            </section>
                                        )
                                    })}
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
};

export default EquipmentCard;
