import React, {useEffect, useState} from 'react';
import {
    IonBackButton, IonButtons, IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonList,
    IonIcon, IonItemDivider, IonAlert, useIonViewWillEnter, IonLoading
} from '@ionic/react';
import {useParams} from "react-router";
import {checkmarkCircleOutline, person, time, alert} from "ionicons/icons";
import "../css/RequestLog.css";
import myFetch from "../utils/MyFetch";
import IAlert from "../interfaces/IAlert";
import IRequestAction from "../interfaces/IRequestAction";
import { formatToLocalTime, formatToLocalDate} from "../utils/ViewHelpers";
import {t} from "../utils/Translation"
import Storage from "../utils/Storage"
import {useGetLang} from "../hooks/useGetLang";

const RequestLog = (props: any) => {

    let {id} = useParams();
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);
    const [requestActions, setRequestActions] = useState<IRequestAction[]>([]);
    const {lang} = useGetLang()

    useIonViewWillEnter(() => {
        reloadPage()
    });

    const reloadPage = () => {
        setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/request-actions?rel_transitions=1&executed=1&rel_performer=1&order_by=updated_at&direction=asc&request_id="+id)
            .then(
                (result:any) => {
                    // This is second data in array. Outside this 'data' are paginator variables
                    if (result.data) {
                        setRequestActions(result.data);
                    }
                    setShowLoading(false);
                },
            )
            .catch((data) => {setAlert({showAlert:true, message:data.message})});
    }

    let currentDate: string;
    let timeLineItems = requestActions.map((item:IRequestAction, index: number) => {

        let divider = () => {
            if (currentDate !== formatToLocalDate(item.updated_at)) {
                currentDate = formatToLocalDate(item.updated_at);

                return (
                <IonItemDivider className="day-divider">
                    <IonLabel>{currentDate}</IonLabel>
                </IonItemDivider>);
            }
            else {
                return '';
            }
        };
        let dividerComponent = divider();

        let positionClass = '';
        if (index === 0) {
            positionClass = 'first';
        } else if (index === requestActions.length-1) {
            positionClass = 'last';
        }

        const stepTitle = () => {
            if (lang !== "sr" && lang !== null) {
                return item.translations[lang].action_name
            }
            return item.action_name
        }

        return(
            <div key={index}>
                {dividerComponent}
                <IonItem className={"timeline-item " + positionClass}>
                    <IonIcon icon={checkmarkCircleOutline} slot="start" color="success"/>
                    <IonLabel>
                        <h4><strong>{stepTitle()}</strong></h4>
                        <p>{item.comment}</p>
                        <div className="timeline-performer">
                            <p className="person"><IonIcon icon={person}/>{(item.first_name === null) ? t('Sistem') : item.first_name+' '+item.last_name}</p>
                            <p className="time"><IonIcon icon={time}/>{formatToLocalTime(item.updated_at)}</p>
                        </div>
                    </IonLabel>
                </IonItem>
            </div>
        );
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{t("Vremenska linija")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonList className="time-line-cont"/>
                    {timeLineItems}
                <IonList/>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />

                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
};

export default RequestLog;
