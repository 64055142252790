import * as firebase from "firebase"
import myFetch from "./utils/MyFetch";
import Storage from "./utils/Storage";

const firebaseConfig = {
    apiKey: "AIzaSyBQBqRx5-k17ZbAyFpQvRIzR_AySwWUXfU",
    authDomain: "revind4-aaf34.firebaseapp.com",
    databaseURL: "https://revind4-aaf34.firebaseio.com",
    projectId: "revind4-aaf34",
    storageBucket: "revind4-aaf34.appspot.com",
    messagingSenderId: "714352833360",
    appId: "1:714352833360:web:0fc7820abe549ed886f255"
};

firebase.initializeApp(firebaseConfig)

export const firebaseMessaging = (handleNotification:any) => {

    if(firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();

        messaging.getToken().then((token) => {
            // console.log(token)
            if (Storage.get("fcm_token") !== token) {
                Storage.set("fcm_token", token)
                let request = {
                    "user_id": Storage.get("user_id"),
                    "fcm_token": token
                }
                myFetch(process.env.REACT_APP_API_URL + "/fcm", {method: 'POST', body: JSON.stringify(request)})
                    .catch((error) => console.log(error));
            }
        });

        messaging.onMessage((payload) => {
            // console.log(payload)
            let title = payload.notification.title
            let body = payload.notification.body
            let object = {display:true, title: title, body: body}
            // console.log(object);
            handleNotification(object);
        })
    }
}

