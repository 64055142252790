import IReduxAction from "../interfaces/IReduxAction";
import {combineReducers} from "redux";

export const userLoginReducer = (user = 0, action: IReduxAction) => {
    if (action.type === "USER_LOGGED_IN") {
        return action.payload;
    } else if (action.type === "USER_LOGGED_OUT"){
        return 0;
    }

    return user;
};

export const setNotificationsNumberReducer = ( number = 0, action: IReduxAction) => {
    if (action.type === "SET_NOTIFICATIONS_NO") {
        return action.payload
    }
    return number
}

export default combineReducers({
    loggedInUser: userLoginReducer,
    notificationsNo: setNotificationsNumberReducer
});