import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonLoading,
    IonAlert,
    IonRippleEffect,
    IonRefresherContent, IonRefresher, useIonViewWillEnter
} from '@ionic/react';
import {
    clipboardOutline, checkmarkDone, peopleOutline, timeOutline, buildOutline, cartOutline, hammerOutline
} from "ionicons/icons";
import "../css/Dashboard.css"
import myFetch from "../utils/MyFetch";
import {random} from "../utils/String";
import {t} from "../utils/Translation";
import IAlert from "../interfaces/IAlert";
import IZahtev from "../interfaces/IZahtev";
import IMachine from "../interfaces/IMachine";
import IUser from "../interfaces/IUser";
import moment from "moment";

const Dashboard = ( props:any ) => {

    const [openRequests, setOpenRequests] = useState({meta: {pagination: {total: 0}}});
    const [inProgressRequests, setInProgressRequests] = useState({meta: {pagination: {total: 0}}});
    const [finishedRequests, setFinishedRequests] = useState({meta: {pagination: {total: 0}}});
    const [waitingRequests, setWaitingRequests] = useState({meta: {pagination: {total: 0}}});
    const [mttr, setMttr] = useState<any>({mttr: '', mtr: ''});
    const [openRequestsArray, setOpenRequestsArray] = useState<IZahtev[]>([]);
    const [inProgressRequestsArray, setInProgressRequestsArray] = useState<IZahtev[]>([]);
    const [machines, setMachines] = useState<IMachine[]>([]);
    const [busyUsers, setBusyUsers] = useState<IUser[]>([]);
    const [usersWaitingFor, setUsersWaitingFor] = useState<IUser[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});

    useIonViewWillEnter(() => {
        reloadPage()
    });

    const doRefresh = (event: CustomEvent) => {
        event.detail.complete();
        setMachines([])
        reloadPage()
    }

    useEffect(() => {
        getMachinesSaKvarovima()
    }, [openRequestsArray, inProgressRequestsArray]);

    const reloadPage = () => {
        setShowLoading(true);
        moment.locale(process.env.REACT_APP_LOCALE);
        let current = moment().format('YYYY-MM-DD');
        let fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        Promise.all([
            myFetch(process.env.REACT_APP_API_URL + "/requests?status=open&dashboard=1&count=1&hash="+random(5))
                .then((data:any) => {
                    setOpenRequests(data)
                }),
            myFetch(process.env.REACT_APP_API_URL + "/requests?status=open&dashboard=1&hash="+random(5))
                .then((data:any) => {
                    // getMachinesSaKvarovima(data)
                    setOpenRequestsArray([...data])
                }),
            myFetch(process.env.REACT_APP_API_URL + "/requests?status=in progress&dashboard=1&count=1&hash="+random(5))
                .then((data:any) => {
                    setInProgressRequests(data)
                }),
            myFetch(process.env.REACT_APP_API_URL + "/requests?status=in progress&dashboard=1&hash="+random(5))
                .then((data:any) => {
                    // getMachinesSaKvarovima(data)
                    setInProgressRequestsArray([...data])
                }),
            myFetch(process.env.REACT_APP_API_URL + "/requests?status=finished&dashboard=1&count=1&hash="+random(5))
                .then((data:any) => {
                    setFinishedRequests(data)
                }),
            myFetch(process.env.REACT_APP_API_URL + "/requests?status=waiting&dashboard=1&count=1&hash="+random(5))
                .then((data:any) => {
                    setWaitingRequests(data)
                }),
            myFetch(process.env.REACT_APP_API_URL + "/reports/mttr?from_date="+fromDate+"&to_date="+current)
                .then((data:any) => {
                    setMttr(data);
                }),
            myFetch(process.env.REACT_APP_API_URL + "/users?waiting_for=1&hash="+random(5))
                .then((data:any) => {
                    setUsersWaitingFor(data);
                }),
            myFetch(process.env.REACT_APP_API_URL + "/users?busy=1&hash="+random(5))
                .then((data:any) => {
                    setBusyUsers(data);
                })
        ]).then(() => {
            setShowLoading(false);
        }).catch((error) => {
            setShowLoading(false);
            setAlert({showAlert: true, message: error.error});
        })
    }

    const getMachinesSaKvarovima = () => {
        let machinesIdsArray:any = []
        openRequestsArray.forEach((value:IZahtev, index:any) => {
            if (value.request_data.data.machine_id !== undefined) {
                if (machinesIdsArray.indexOf(value.request_data.data.machine_id) === -1) {
                    machinesIdsArray = [...machinesIdsArray, value.request_data.data.machine_id]
                }
            }
        })
        inProgressRequestsArray.forEach((value:IZahtev, index:any) => {
            if (value.request_data.data.machine_id !== undefined) {
                if (machinesIdsArray.indexOf(value.request_data.data.machine_id) === -1) {
                    machinesIdsArray = [...machinesIdsArray, value.request_data.data.machine_id]
                }
            }
        })
        let machineIds = machinesIdsArray.join(",");
        if (machinesIdsArray.length > 0) {
            Promise.all([
                myFetch(process.env.REACT_APP_API_URL + "/production-line-machines?ids="+machineIds)
                    .then((data:any) => {
                        setMachines([...machines, ...data])
                    }),
            ])
            .catch((error) => {
                setAlert({showAlert: true, message: error.error});
            })
        }
    }

    const displayMachineNames = () => {
        if (machines.length > 0) {
            return (
                <ul className="machines">
                    {machines.map((machine:IMachine, index) => {
                        return (
                            <li key={index}>
                                {machine.name}
                            </li>
                        )
                    })}
                </ul>
            )
        } else {
            return (
                <ul className="machines">
                    <li>{t("Nema problema")}</li>
                </ul>
            )
        }
    }

    const displayUsersWaitingForAction = () => {
        if (usersWaitingFor.length > 0) {
            return (
                <ul className="machines">
                    {usersWaitingFor.map((user, index) => {
                        return (
                            <li key={index} className={user.logged_in ? '' : 'logged-off'}>
                                {user.first_name} {user.last_name}
                            </li>
                        )
                    })}
                </ul>
            )
        } else {
            return (
                <ul className="machines">
                    <li>{t("Nema korisnika na čiju se akciju čeka")}</li>
                </ul>
            )
        }
    }

    const displayBusyUsers = () => {
        if (busyUsers.length > 0) {
            return (
                <ul className="machines">
                    {busyUsers.map((user, index) => {
                        return (
                            <li key={index}>
                                {user.first_name} {user.last_name}
                            </li>
                        )
                    })}
                </ul>
            )
        } else {
            return (
                <ul className="machines">
                    <li>{t("Svi su na raspolaganju")}</li>
                </ul>
            )
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t("Dashboard")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="content">
                <IonGrid>
                    <IonRow>
                        <IonCol size="6">
                            {/*<IonCard onClick={() => props.history.push('/zahtevi')} className="ion-activatable ripple-parent">*/}
                            <IonCard button={true} href="/zahtevi/open">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Otvoreno zahteva")}</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color={openRequests.meta.pagination.total === 0 ? 'primary' : 'danger'} icon={clipboardOutline} />
                                        {openRequests.meta.pagination.total}
                                    </IonCardTitle>
                                    <IonRippleEffect />
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard button={true} href="/zahtevi/in progress">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Rešavanje u toku")}</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color={inProgressRequests.meta.pagination.total === 0 ? 'primary' : 'danger'} icon={hammerOutline} />
                                        {inProgressRequests.meta.pagination.total}
                                    </IonCardTitle>
                                    <IonRippleEffect />
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard button={true} href="/zahtevi/waiting">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Zahteva na čekanju")}</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color={waitingRequests.meta.pagination.total === 0 ? 'primary' : 'danger'} icon={cartOutline} />
                                        {waitingRequests.meta.pagination.total}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard button={true} href="/zahtevi/finished">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Rešeno zahteva")}</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color="primary" icon={checkmarkDone} />
                                        {finishedRequests.meta.pagination.total}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard button={true} href="/reports/mttr">
                                <IonCardHeader>
                                    <IonCardSubtitle>Mttr (min)</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color="primary" icon={timeOutline} />
                                        {mttr.mttr !== '' ? Math.round(mttr.mttr / 60) : ''}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard button={true} href="/reports/mttr">
                                <IonCardHeader>
                                    <IonCardSubtitle>Ttr (min)</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color="primary" icon={timeOutline} />
                                        {mttr.mtr !== '' ? Math.round(mttr.mtr / 60) : ''}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Kvarovi na mašinama")}</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color={machines.length === 0 ? 'primary' : 'danger'} icon={buildOutline} />
                                        {machines.length === 0 ? '' : machines.length}
                                        {displayMachineNames()}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Čeka se na akciju od")}:</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color="primary" icon={peopleOutline} />
                                        {busyUsers.length === 0 ? '' : busyUsers.length}
                                        {displayUsersWaitingForAction()}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("Zauzeti")}:</IonCardSubtitle>
                                    <IonCardTitle>
                                        <IonIcon color="primary" icon={peopleOutline} />
                                        {busyUsers.length === 0 ? '' : busyUsers.length}
                                        {displayBusyUsers()}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent />
                </IonRefresher>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
};

export default Dashboard;