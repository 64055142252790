import React, {useState} from 'react';

import {IonIcon, IonFabButton, IonFabList, IonFab} from '@ionic/react';
import {
    apps,
    arrowForward,
    arrowUp,
    cart,
    checkmark,
    close,
    construct, checkmarkDone,
    help,
    people
} from 'ionicons/icons';
import '../css/FilterRequestsMenu.css';
import ITransition from "../interfaces/ITransition";
import IRequestAction from "../interfaces/IRequestAction";

type ActionsButtonProps = {
    actions: ITransition[],
    onClick: any,
    disabled: boolean,
    setDisabled: any
}

const ActionsButton = (props:ActionsButtonProps) => {

    let actionsSorted = [...props.actions];
    let showButtonOpen = false;

    actionsSorted.sort((a: ITransition, b: ITransition) => {
        let colorsOrder: { [key: string]: number } = {
            primary: 1,
            success: 2,
            warning: 3,
            danger: 4,
            medium: 5,
            light: 6,
            dark: 7,
        };

        if (colorsOrder[a.button_color] > colorsOrder[b.button_color]) {
            return 1
        }
        return -1;
    });

    let topDirectionButtons: Array<JSX.Element>  = [];
    let startDirectionButtons: Array<JSX.Element>  = [];

    const onClickButton = (requestAction: IRequestAction) => {
        props.setDisabled(true)
        props.onClick(requestAction)
    }

    actionsSorted.forEach((requestAction:any, index) => {
        let showButton: boolean = requestAction.button_activated === 1;
        if (showButton) {
            showButtonOpen = true;
        }
        switch(requestAction.button_type) {
            case 'approve':
                topDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="primary" onClick={() => onClickButton(requestAction)}><IonIcon icon={checkmark} /></IonFabButton>
                );
                break;
            case 'deny':
                topDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="danger" onClick={() => onClickButton(requestAction)}><IonIcon icon={close} /></IonFabButton>
                );
                break;
            case 'close':
                topDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="primary" onClick={() => onClickButton(requestAction)}><IonIcon icon={checkmarkDone} /></IonFabButton>
                );
                break;
            case 'forward':
                startDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="primary" onClick={() => onClickButton(requestAction)}><IonIcon icon={arrowForward} /></IonFabButton>
                );
                break;
            case 'user_group':
                startDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="success" onClick={() => onClickButton(requestAction)}><IonIcon icon={people} /></IonFabButton>
                );
                break;
            case 'higher_level':
                startDirectionButtons.push(
                    <IonFabButton show={showButton}  key={index} color="warning" onClick={() => onClickButton(requestAction)}><IonIcon icon={arrowUp} /></IonFabButton>
                );
                break;
            case 'external_service':
                startDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="success" onClick={() => onClickButton(requestAction)}><IonIcon icon={construct} /></IonFabButton>
                );
                break;
            case 'purchasing':
                startDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="success" onClick={() => onClickButton(requestAction)}><IonIcon icon={cart} /></IonFabButton>
                );
                break;
            case 'unknown':
                startDirectionButtons.push(
                    <IonFabButton show={showButton} key={index} color="danger" onClick={() => onClickButton(requestAction)}><IonIcon icon={help} /></IonFabButton>
                );
                break;
            default:
                break;
        }
    });

    return (
        <IonFab vertical="bottom" horizontal="end" slot="fixed" class="ion-margin-end" activated={showButtonOpen}>
            <IonFabButton disabled={props.disabled}>
                <IonIcon icon={apps} />
            </IonFabButton>
            <IonFabList side="top" activated={showButtonOpen}>
                {topDirectionButtons}
            </IonFabList>
            <IonFabList side="start">
                {startDirectionButtons}
            </IonFabList>
        </IonFab>
    );
}

export default ActionsButton;