import React, {useEffect, useState} from 'react';
import {
    IonButtons,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLoading,
    IonAlert,
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonIcon, IonMenuButton, IonInput,
    IonButton, IonToggle, IonSelect, IonSelectOption
} from '@ionic/react';

import IAlert from "../interfaces/IAlert";
import {personCircleOutline} from "ionicons/icons";
import "../css/ProfilePage.css"
import Storage from "../utils/Storage";
import myFetch from "../utils/MyFetch";
import IUser from "../interfaces/IUser";
import {t} from "../utils/Translation"


const ProfilePage = (props: any) => {

    const userId = Storage.get("user_id");
    const [showLoading, setShowLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [user, setUser] = useState<IUser>({
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        created_requests: 0,
        resolved_requests: 0,
        logged_in: 0,
        notif_emails_when_off: 0,
        created_at: '',
        updated_at: '',
        links: {
            self: {rel:'', url:''},
            company: {rel:'', url:''},
            role: {rel:'', url:''},
            department: {rel:'', url:''}
        }
    })
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [language, setLanguage] = useState(Storage.get("lang"));
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setShowLoading(true);
        Promise.all([
            myFetch(process.env.REACT_APP_API_URL + "/users/"+userId+"?include=roles,created")
                .then((data:any) => {
                    setUser(data)
                    setLanguage(Storage.get("lang"))
                    if (language === undefined || language === "" || language === null) {
                        Storage.set("lang", "en")
                        setLanguage("en")
                    }
                    setShowLoading(false);
                }),
        ]).catch((error) => {
            setShowLoading(false);
            setAlert({showAlert:true, message:error.message})
        });
    }, [userId, reload]);

    const getRoleNames = () => {
        let roles:string[] = [];
        user.roles?.data.forEach((value, index) => {
            roles.push(value.name);
        })
        return roles.join(", ");
    }

    const submitPassword = () => {
        if (password === '' || confirmPassword === '') {
            setAlert({showAlert:true, message:t('Lozinka ne može biti prazna')})
            return false;
        }
        if (password !== confirmPassword) {
            setAlert({showAlert:true, message:t('Lozinka i potvrda lozinke moraju biti iste')})
            return false;
        }
        if (password.length < 4 || confirmPassword.length < 4) {
            setAlert({showAlert:true, message:t('Lozinka i potvrda lozinke moraju imati min 4 karaktera')})
            return false;
        }

        setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/passwords", {method: 'POST', body: JSON.stringify({password:password})})
            .then((res:any) => {
                if (res.success) {
                    setShowLoading(false);
                    setAlert({showAlert:true, message:t("Lozinka uspešno kreirana"), header: t("Uspešna akcija!")});
                } else if (res.error) {
                    setShowLoading(false);
                    setAlert({showAlert:true, message:res.error.message, header: t("Greška")})
                }
            })
            .catch((res) => {
                setShowLoading(false);
                setAlert({showAlert:true, message:res.error.message, header: t("Greška")})}
            );
    }

    const setReceiveNotifEmailWhenOff = (value:any) => {
        if (user.id !== 0) {
            let data = {}
            if (value === true) {
                data = {notif_emails_when_off: 1}
            } else {
                data = {notif_emails_when_off: 0}
            }
            myFetch(process.env.REACT_APP_API_URL + "/users/"+user.id, {method: 'PUT', body: JSON.stringify(data)})
                .then((response:any) => {})
                .catch((error:any) => {console.log(error)})
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t("Moji podaci")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div id="avatar-cont">
                    <IonAvatar class="avatar">
                        <IonIcon icon={personCircleOutline} color={"light"} class="avatar"/>
                    </IonAvatar>
                    <p><strong>{user.first_name + ' ' + user.last_name}</strong></p>
                    <p>{user.email}</p>
                    <p>{user.phone}</p>
                    <p>{getRoleNames()}</p>
                </div>

                <IonList>
                    <IonItem detail={true}>
                        <IonLabel>{t("Kreirao zahteva")}</IonLabel>
                        <p slot="end">{user.created_requests}</p>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonLabel>{t("Rešio zahteva")}</IonLabel>
                        <p slot="end">{user.resolved_requests}</p>
                    </IonItem>
                    <IonItem>
                        <IonLabel>{t("Nova lozinka")}</IonLabel>
                        <IonInput
                            clearInput
                            inputMode="text"
                            required={false}
                            value={password}
                            onIonChange={(e) => {setPassword((e.target as HTMLInputElement).value)}}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>{t("Potvrda lozinke")}</IonLabel>
                        <IonInput
                            clearInput
                            inputMode="text"
                            required={false}
                            value={confirmPassword}
                            onIonChange={(e) => {setConfirmPassword((e.target as HTMLInputElement).value)}}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>{t("Želim notifikacije i offline")}</IonLabel>
                        <IonToggle color="primary" checked={user.notif_emails_when_off === 1} onIonChange={e => setReceiveNotifEmailWhenOff(e.detail.checked)} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t("Jezik")}</IonLabel>
                        <IonSelect
                          selectedText={language === "en" ? t("Engleski") : t("Srpski")}
                          cancelText={t("Otkaži")}
                          onIonChange={(e) => {Storage.set("lang", (e.target as HTMLInputElement).value); setLanguage((e.target as HTMLInputElement).value); setReload(!reload) }}>
                            <IonSelectOption key={3} value='sr'>
                                {t("Srpski")}
                            </IonSelectOption>
                            <IonSelectOption key={2} value='en'>
                                {t("Engleski")}
                            </IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="none">
                        <IonButton
                            color="secondary"
                            size="default"
                            type="button"
                            class="submit-button"
                            onClick={() => submitPassword()}
                        >
                            {t("Promeni lozinku")}
                        </IonButton>
                    </IonItem>
                </IonList>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
};

export default ProfilePage;
