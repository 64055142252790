import {
    IonAlert,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonBadge,
    IonMenuButton,
    IonButtons, IonButton, IonIcon,
    IonMenuToggle, IonLoading, IonRefresher, IonRefresherContent,
    useIonViewWillEnter, IonBackButton
} from '@ionic/react';
import {search} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import '../css/Requests.css';
import {formatToLocalDateTime, getColorforStatus} from "../utils/ViewHelpers";
import myFetch from "../utils/MyFetch";
import { connect } from "react-redux";
import { loginUser } from "../actions";
import IZahtev from "../interfaces/IZahtev";
import IAlert from "../interfaces/IAlert";
import FilterRequestsMenu from "../components/FilterRequestsMenu";
import IProductionLinePart from "../interfaces/IProductionLinePart";
import IUser from "../interfaces/IUser";
import moment from "moment";
import {useParams} from "react-router";
import {t} from "../utils/Translation"

const Requests: React.FC = (props:any) => {

    let {status} = useParams()
    const [zahtevi, setZahtevi] = useState([]);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);
    const [machines, setMachines] = useState<IProductionLinePart[]>([]);
    const [technicians, setTechnicians] = useState<IUser[]>([]);

    useEffect(() => {
        reloadPage(status)
    }, [status]);

    // useIonViewWillEnter(() => {
    //     reloadPage(status)
    // });

    const doRefresh = (event: CustomEvent) => {
        event.detail.complete();
        reloadPage(status)
    }

    const reloadPage = (status:any) => {
        setShowLoading(true);
        let promiseArray = []
        if (typeof(status) === "undefined") {
            promiseArray.push(myFetch(process.env.REACT_APP_API_URL + "/requests?include=state")
                .then((data:any) => {setZahtevi(data)}))
        } else {
            promiseArray.push(myFetch(process.env.REACT_APP_API_URL + "/requests?include=state&status="+status)
                .then((data:any) => {setZahtevi(data)}))
        }
        promiseArray.push(myFetch(process.env.REACT_APP_API_URL + "/production-line-machines")
            .then((data:any) => {setMachines(data)}))
        promiseArray.push(myFetch(process.env.REACT_APP_API_URL + "/users?role=technician")
            .then((data:any) => {setTechnicians(data)}))
        Promise.all(promiseArray).then(() => {
            setShowLoading(false);
        }).catch((data) => {
            setAlert({showAlert:true, message:data.message})
        });
    }

    let itemsArray: Array<JSX.Element> = zahtevi.map((value: IZahtev, index) => {
        moment.locale(process.env.REACT_APP_LOCALE);
        let current = moment();
        let requestEscalationTimeTreshold = moment(value.created_at).add(1, 'hour')
        let escalateCssClass = requestEscalationTimeTreshold < current && value.priority === 'high' && (value.status === 'open' || value.status === 'in progress') ? 'red-background' : '';
        return (
            <IonItem className={escalateCssClass} key={index} routerLink={"/requests/details/"+value.id} routerDirection="forward" detail={true} >
                <IonLabel>
                    <h2>{value.title}</h2>
                    <p>{t(value.state?.data.name)}</p>
                    <p>{formatToLocalDateTime(value.created_at)}</p>
                </IonLabel>
                <IonBadge color={getColorforStatus(value.status)}>{value.status}</IonBadge>
            </IonItem>
        );
    });

    // if (props.loggedInUser === 0) {
    //     console.log("User nije ulogovan");
    //     props.loginUser(1);
    // } else {
    //     console.log("User je ulogovan");
    // }

    const handleOnClickFilter = (data:any) => {
        setShowLoading(true);
        let dataWithStateIncluded = {...data, include: "state"};
        let queryString = Object.keys(dataWithStateIncluded).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(dataWithStateIncluded[key])
        }).join('&');
        myFetch(process.env.REACT_APP_API_URL + "/requests?"+queryString)
            .then((data:any) => {
                setZahtevi(data);
                setShowLoading(false);
            })
            .catch((data) => {
                setShowLoading(false);
                setAlert({showAlert:true, message:data.message})
            });
    }

    return (
        <IonPage>
            <FilterRequestsMenu
                onFilterClicked={handleOnClickFilter}
                machines={machines}
                technicians={technicians}
            />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t("Zahtevi")}</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuToggle menu="filter">
                            <IonButton>
                                <IonIcon slot="icon-only" icon={search} />
                            </IonButton>
                        </IonMenuToggle>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent />
                </IonRefresher>

                <IonList>
                    {itemsArray}
                </IonList>

                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert:false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t('Učitavanje podataka')+'...'}
                    duration={10000}
                />

            </IonContent>
        </IonPage>
    );
};

// Receives all data from store and filter only states we care about in this component
const mapStateToProps = (state:any) => {
    // key: loggedInUser is prop
    // value: state.loggedInUser is value returned from redux store we care about in this component
    return {
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToProps, {loginUser})(Requests);
