import React, {useEffect, useState} from "react";
import {
    IonPage,
    IonTitle,
    IonHeader,
    IonContent,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonAlert, IonLoading, IonCheckbox,
    IonText
} from "@ionic/react";
import {connect} from "react-redux";
import IAlert from "../interfaces/IAlert";
import IJwt from "../interfaces/IJwt"
import {loginUser} from "../actions";
import jwtDecode from 'jwt-decode';
import '../css/Login.css';
import Storage from "../utils/Storage";
import {t} from "../utils/Translation"

const Login = (props:any) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const handleSendForm = () => {
        if (username === '' || password === '') {
            setShowAlert({showAlert: true, message: "Morate uneti username i password"});
            return;
        }
        fetchToken(username, password);
    };

    const fetchToken = ( username: string, password:string ) => {
        setShowLoading(true)
        fetch(process.env.REACT_APP_API_URL + "/auth/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({username:username, password:password})
        })
        .then(responose => responose.json())
        .then(
            (result) => {
                if (result.token && result.token.length > 0) {
                    const decoded: IJwt = jwtDecode(result.token);
                    Storage.set("jwt", result.token);
                    Storage.set("user_id", decoded.sub.toString());
                    if (rememberMe) {
                        Storage.set("username", username);
                        Storage.set("password", password);
                    }
                    props.loginUser(1);
                    setShowLoading(false)
                    // This condition is true ONLY when users goes directly to /login
                    if (props.history) {
                        props.history.push('/home');
                    }
                } else if(result.error && result.error.message.length > 0) {
                    props.loginUser(0);
                    setShowLoading(false)
                    setShowAlert({showAlert:true, message:result.error.message});
                }
            },
            (error) => {
                setShowLoading(false)
                setShowAlert({showAlert:true, message:error.error.message});
            }
        )
    };

    useEffect(() => {
        let rememberMeStorage = Storage.get("remember_me");
        if (rememberMeStorage === '1') {
            setRememberMe(true)
            let username = Storage.get("username") ?? ''
            let password = Storage.get("password") ?? ''
            if ( username !== '' && password !== '') {
                setUsername(username)
                setPassword(password)
            }
        } else {
            setRememberMe(false)
            Storage.remove("username")
            Storage.remove("password")
        }
    }, []);

    const handleRememberMe = (value:any) => {
        if (value === true) {
            Storage.set("remember_me", '1');
            setRememberMe(true);
        } else {
            Storage.remove("remember_me");
            setRememberMe(false);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="background-login">

                <IonItem class="transparent">
                    <IonLabel color="primary" position="stacked">
                        Korisničko ime
                    </IonLabel>
                    <IonInput
                        value={username}
                        placeholder="Unesite korisničko ime"
                        onIonChange={e => setUsername(e.detail.value!)}
                        required
                    />
                </IonItem>

                <IonItem class="transparent">
                    <IonLabel color="primary" position="stacked">
                        Šifra
                    </IonLabel>
                    <IonInput
                        type="password"
                        value={password}
                        placeholder="Unesite šifru"
                        onIonChange={e => setPassword(e.detail.value!)}
                        required
                    />
                </IonItem>

                <IonItem class="transparent">
                    <IonLabel>{t("Zapamti me")}</IonLabel>
                    <IonCheckbox slot="start" checked={rememberMe} onIonChange={e => handleRememberMe(e.detail.checked)} />
                </IonItem>

                <IonButton class="login-button" onClick={handleSendForm} expand="block">{t("Prijavi se")}</IonButton>

                <IonItem class="transparent">
                    <IonText color="primary">
                        <h3>{process.env.REACT_APP_LOGIN_TITLE ?? ''}</h3>
                    </IonText>
                </IonItem>


                <IonAlert
                    isOpen={showAlert.showAlert}
                    onDidDismiss={() => setShowAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={showAlert.message}
                    buttons={['OK']}
                />
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />

            </IonContent>
        </IonPage>
    );
};

// Receives all data from store and filter only states we care about in this component
const mapStateToProps = (state:any) => {
    // key: loggedInUser is prop
    // value: state.loggedInUser is value returned from redux store we care about in this component
    return {
        loggedInUser: state.loggedInUser
    };
};
export default connect(mapStateToProps, {loginUser})(Login);