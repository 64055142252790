import React from 'react';
import {IonIcon} from '@ionic/react';
import {person, time, calendar} from "ionicons/icons";
import {formatToLocalDate, formatToLocalTime} from "../utils/ViewHelpers";
import IUser from "../interfaces/IUser";
import "../css/UserPerformer.css";
import {t} from "../utils/Translation"

type UserPerformerProps = {
    user?: IUser,
    time?: string,
    date?: string
}
const UserPerformer = (props:UserPerformerProps) => {
    let dateTimeSection:JSX.Element|null = null;
    if (props.time) {
        dateTimeSection = <p className="time"><IonIcon icon={time}/>{formatToLocalTime(props.time)}</p>;
    } else if (props.date) {
        dateTimeSection = <p className="time"><IonIcon icon={calendar}/>{formatToLocalDate(props.date)}</p>;
    }
    if (props.user) {
        return (
            <div className="timeline-performer">
                <p className="person"><IonIcon icon={person}/>{(props.user.first_name === null) ? t('Sistem') : props.user.first_name+' '+props.user.last_name}</p>
                {dateTimeSection}
            </div>
        )
    } else {
        return null;
    }

}

export default UserPerformer;