import {
    IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel,
    IonBadge, IonMenuButton, IonButtons, IonRefresherContent, IonRefresher, IonLoading, useIonViewWillEnter
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import '../css/Requests.css';
import IZahtev from "../interfaces/IZahtev";
import IAlert from "../interfaces/IAlert";
import myFetch from "../utils/MyFetch";
import {formatToLocalDateTime, getColorforStatus} from "../utils/ViewHelpers";
import {t} from "../utils/Translation"

const Zadaci: React.FC = () => {

    const [zahtevi, setZahtevi] = useState([]);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);

    useIonViewWillEnter(() => {
        reloadPage()
    });

    const doRefresh = (event: CustomEvent) => {
        event.detail.complete();
        reloadPage()
    }

    const reloadPage = () => {
        setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/zadaci")
            .then((res:any) => {
                setZahtevi(res)
                setShowLoading(false);
            })
            .catch((data) => {
                setShowLoading(true);
                setAlert({showAlert:true, message:data.message})
            });
    }

    let itemsArray: Array<JSX.Element> = zahtevi.map((value: IZahtev, index) => {
        return (
            <IonItem key={index} routerLink={"/requests/details/"+value.id} routerDirection="forward" detail={true} >
                <IonLabel>
                    <h2>{value.title}</h2>
                    <p>{value.state?.data.name}</p>
                    <p>{formatToLocalDateTime(value.created_at)}</p>
                </IonLabel>
	            <IonBadge color={getColorforStatus(value.status)}>{value.status}</IonBadge>
            </IonItem>
        );
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t("Moji zadaci")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent />
                </IonRefresher>

                <IonList>
                    {itemsArray}
                </IonList>

                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert:false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />

            </IonContent>
        </IonPage>
    );
};

export default Zadaci;
