import {createStore} from "redux";
import reducers from "../reducers";
import Storage from "./Storage"

const myFetch = (url: string, options:RequestInit = {}) => {
    // Get redux store
    const store = createStore(reducers);

    // Default headers
    options.headers = {
        'Content-Type': 'application/json'
    };

    // First thing we want is to insert jwt into header
    options = insertJwtHeader(options);

    return new Promise((resolve, reject) => {
        if (!url) {
            reject("Neočekivana greška: fetch url nije definisan!");
        }
        const timeoutId = setTimeout(() => {
            reject(new Error("Spora internet konekcija ili internet u prekidu."))
        }, 20000);
        fetch(url, options)
        .then(responose => responose.json())
        .then(
            (result) => {
                clearTimeout(timeoutId);
                // We received data, data can be 0 hence this hasOwnProperty check
                if ( result.hasOwnProperty('data') ) {
                    resolve(result.data);
                } else if (result.hasOwnProperty('success')) {
                    resolve(result);
                }
                else if (result.hasOwnProperty('error')) {
                    if (result.error.code) {
                        // If unauthorized 401
                        if (result.error.code === 401) {
                            // let storage = new Storage();
                            Storage.remove("jwt")
                            Storage.remove("user_id")
                            Storage.remove("fcm_token")
                            // store.dispatch(loginUser(0));  <-- removed this approach cause store is reset when user hit refresh
                            window.location.href = "/login";
                        }
                    }
                    // We received error generated by our app, like no user found, or wrong token
                    if (result.error.message) {
                        let alertMessage = {
                            showAlert: true,
                            message: result.error.message
                        };
                        reject(alertMessage);
                    }
                }
            },
            // System error, like network error for eg
            (error) => {
                clearTimeout(timeoutId);
                if (error) {
                    let alertMessage = {
                        showAlert: true,
                        message: 'Spora internet konekcija ili internet u prekidu.'
                    };
                    reject(alertMessage);
                }
            });
    });
};

const insertJwtHeader = (options:RequestInit) => {
    let jwt = localStorage.jwt;

    if (jwt && jwt.length > 0) {
        let headers;
        if (options.headers) {
            headers = {...options.headers, Bearer: jwt};
        } else {
            headers = {Bearer: jwt}
        }
        options.headers = headers;
    }
    return options;
};

export default myFetch;