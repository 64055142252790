import React, {useState} from 'react';
import {
    IonButtons,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLoading,
    IonAlert,
    IonItem,
    IonLabel,
    IonInput,
    IonBackButton,
    useIonViewWillEnter,
    IonTextarea,
    IonSelect,
    IonSelectOption, IonButton
} from '@ionic/react';
import IZahtev from "../interfaces/IZahtev";
import myFetch from "../utils/MyFetch";
import {useParams} from "react-router";
import IAlert from "../interfaces/IAlert";
import IGroup from "../interfaces/IGroup";
import IMachine from "../interfaces/IMachine";
import IProductionLinePart from "../interfaces/IProductionLinePart";
import {t} from "../utils/Translation"


const RequestEdit = (props: any) => {

    let {id} = useParams();
    const [showLoading, setShowLoading] = useState(false)
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""})
    const [zahtev, setZahtev] = useState<IZahtev>({
        id: 0,
        company_id: 0,
        process_id: 0,
        user_id: 0,
        ip_v4: '',
        title: '',
        description: '',
        current_state: 0,
        status: '',
        priority: '',
        can_take: false,
        created_at: '',
        updated_at: '',
        links: {
            self: {rel: '', url: ''},
            user: {rel: '', url: ''},
            current_state: {rel: '', url: ''},
            production_line: {rel: '', url: ''},
            production_line_machine: {rel: '', url: ''},
            production_line_part: {rel: '', url: ''},
            responsible_group: {rel: '', url: ''},
            "request-actions": {rel: '', url: ''},
            "executed-actions": {rel: '', url: ''},
            transitions: {rel: '', url: ''}
        },
        request_data: {
            data: {
                production_line_id: 0,
                responsible_group_id: 0,
            }
        },
        request_images: {
            data: []
        }
    })
    const [groups, setGroups] = useState<IGroup[]>([])
    const [priority, setPriority] = useState({value: 'normal', name: 'Srednji'})
    const [machines, setMachines] = useState<IMachine[]>([])
    const [productionLine, setProductionLine] = useState<IProductionLinePart>()
    const [dropdownParts, setDropdownParts] = useState<Array<any>[]>([])

    useIonViewWillEnter(() => {
        reloadPage()
    });

    const updateDropdownparts = (index:any, data:any) => {
        let newList = []
        newList[index] = data
        setDropdownParts([...dropdownParts, ...newList])
    }

    const reloadPage = () => {
        setShowLoading(true);
        Promise.all([
            myFetch(process.env.REACT_APP_API_URL + "/requests/"+id+"?include=responsible_persons,request_images,resolve_user,comments.comment_creator")
                .then(
                    (result:any) => {
                        if (result) {
                            setZahtev(result);
                            onPrioritySelected(result.priority)
                            myFetch(process.env.REACT_APP_API_URL + "/production-lines/"+result.request_data.data.production_line_id+"?include=parents")
                                .then((data:any) => {
                                    setProductionLine(data);
                                    let promiseArray:any = [];
                                    if (data) {
                                        if (data.parents) {
                                            data.parents.data.reverse().map((value:any, index:any) => {
                                                console.log(index)
                                                console.log(value)
                                                let parent_id = value.parent_id === null ? 0 : value.parent_id;
                                                let url = process.env.REACT_APP_API_URL + "/production-lines?parent_id="+parent_id+"&type="+value.type;
                                                promiseArray.push(myFetch(url).then(data => {
                                                    updateDropdownparts(index, data);
                                                }))
                                            })
                                        }
                                        let parent_id = data.parent_id === null ? 0 : data.parent_id;
                                        let url = process.env.REACT_APP_API_URL + "/production-lines?parent_id="+parent_id+"&type="+data.type;
                                        promiseArray.push(myFetch(url).then(data => {console.log(data)}))
                                        Promise.all(promiseArray).catch((error) => {
                                            console.log(error);
                                        })
                                    }
                                })
                        } else {
                            // Here comes error generated from api. Response is received but error happend on API
                            setAlert({showAlert: true, message: result.error});
                        }
                        setShowLoading(false);
                    },
                ),
            myFetch(process.env.REACT_APP_API_URL + "/groups")
                .then((data:any) => {
                    setGroups(data)
                    setShowLoading(false);
                }),
            myFetch(process.env.REACT_APP_API_URL + "/production-line-machines")
                .then((data:any) => {
                    setMachines(data)
                }),
        ]).catch((data) => {setAlert({showAlert:true, message:data.message})});
    }

    const onPrioritySelected = ( value: string) => {
        switch (value) {
            case 'low':
                setPriority({value: 'low', name: 'Nizak'});
                break;
            case 'normal':
                setPriority({value: 'normal', name: 'Srednji'});
                break;
            case 'high':
                setPriority({value: 'high', name: 'Visok'});
                break;
        }
    }

    const handleSubmit = () => {
        return;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Ispravljanje zahteva</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">Naslov</IonLabel>
                    <IonInput
                        clearInput
                        inputMode="text"
                        required={true}
                        value={zahtev.title}
                        onIonChange={(e) => {setZahtev({...zahtev, title: (e.target as HTMLInputElement).value})}}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Opis kvara</IonLabel>
                    <IonTextarea
                        rows={3}
                        required
                        value={zahtev.description}
                        onIonChange={(e) => {setZahtev({...zahtev, description: (e.target as HTMLInputElement).value})}}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Tip kvara</IonLabel>
                    <IonSelect cancelText={t("Otkaži")} onIonChange={(e) => {setZahtev({...zahtev, description: (e.target as HTMLInputElement).value})}}>
                        {groups.map((object, index) => {
                            return (
                                <IonSelectOption key={index} value={object.id}>
                                    {object.name}
                                </IonSelectOption>
                            );
                        })}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Prioritet</IonLabel>
                    <IonSelect selectedText={priority.name} cancelText={t("Otkaži")} onIonChange={(e) => {onPrioritySelected((e.target as HTMLIonSelectElement).value)}}>
                        <IonSelectOption key={3} value='high'>
                            Visok
                        </IonSelectOption>
                        <IonSelectOption key={2} value='normal'>
                            Srednji
                        </IonSelectOption>
                        <IonSelectOption  key={1} value='low'>
                            Nizak
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Mašina</IonLabel>
                    <IonSelect cancelText={t("Otkaži")} onIonChange={(e) => {setZahtev({...zahtev, description: (e.target as HTMLInputElement).value})}}>
                        {machines.map((object, index) => {
                            return (
                                <IonSelectOption key={index} value={object.id}>
                                    {object.name}
                                </IonSelectOption>
                            );
                        })}
                    </IonSelect>
                </IonItem>

                <IonButton expand="block" onClick={handleSubmit}>Snimi</IonButton>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />

                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    )
}

export default RequestEdit