import React, {useEffect, useState} from 'react';
import {
    IonAlert, IonBackButton, IonButtons, IonContent,
    IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonTitle, IonToolbar
} from '@ionic/react';
import IMachine from "../interfaces/IMachine";
import IAlert from "../interfaces/IAlert";
import myFetch from "../utils/MyFetch";
import {t} from "../utils/Translation"

const EquipmentCardsList = (props:any) => {

    const [machinesList, setMachinesList] = useState<IMachine[]>([])
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        setShowLoading(true);
        Promise.all([
            myFetch(process.env.REACT_APP_API_URL + "/production-line-machines")
                .then((data:any) => {
                    setMachinesList(data)
                }),
        ]).then(() => {
            setShowLoading(false);
        }).catch((error) => {
            setShowLoading(false);
            setAlert({showAlert: true, message: error.error});
        })
    },[])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{t("Lista mašina")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {machinesList.map((machine, index) => {
                        return (
                            <IonItem key={index} routerLink={"/equipment-card/"+machine.id} routerDirection="forward" detail={true} >
                                <IonLabel>
                                    <h2>{machine.name}</h2>
                                    <p>{machine.description}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    )
}

export default EquipmentCardsList;