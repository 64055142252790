export default class Storage {

    static set(name:"user_id"|"jwt"|"fcm_token"|"remember_me"|"username"|"password"|"lang", value:string) {
        localStorage.setItem(name, value)
    }

    static get(name:"user_id"|"jwt"|"fcm_token"|"remember_me"|"username"|"password"|"lang") {
        return localStorage.getItem(name)
    }

    static remove(name:"user_id"|"jwt"|"fcm_token"|"remember_me"|"username"|"password"|"lang")
    {
        localStorage.removeItem(name)
    }
}