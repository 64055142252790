import React, {useEffect, useState} from 'react';
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/react';
import {t} from "../utils/Translation"

type Props = {
    onStatusSelected: any,
    resetFlag: boolean
}

const StatusSelectItem = ({onStatusSelected, resetFlag}: Props) => {

    const [status, setStatus] = useState();

    const handleOnStatusSelected = (value: string) => {
        onStatusSelected(value)
        setStatus(value)
    }

    useEffect(() => {
        setStatus(undefined)
    }, [resetFlag])

    return (
        <IonItem>
            <IonLabel position="floating">{t("Status")}:</IonLabel>
            <IonSelect value={status} cancelText={t("Otkaži")} placeholder={t("Izaberite")} onIonChange={(e) => {
                handleOnStatusSelected((e.target as HTMLIonSelectElement).value)
            }}>
                <IonSelectOption key={1} value="open">
                    {t("Otvoren")}
                </IonSelectOption>
                <IonSelectOption key={2} value="in progress">
                    {t("U toku")}
                </IonSelectOption>
                <IonSelectOption key={3} value="resolved">
                    {t("Rešeno")}
                </IonSelectOption>
                <IonSelectOption key={4} value="denied">
                    {t("Odbijeno")}
                </IonSelectOption>
                <IonSelectOption key={5} value="finished">
                    {t("Završeno")}
                </IonSelectOption>
                <IonSelectOption key={6} value="waiting">
                    {t("Na čekanju")}
                </IonSelectOption>
                <IonSelectOption key={7} value="error">
                    {t("Greška")}
                </IonSelectOption>
            </IonSelect>
        </IonItem>
    );
};

export default StatusSelectItem;
