import React, {useEffect, useState} from 'react';
import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage, IonRefresher, IonRefresherContent, IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar, useIonViewWillEnter
} from '@ionic/react';
import {
    people,
    person,
    images,
    create
} from "ionicons/icons";
import {useParams} from "react-router";
import moment from "moment";
import 'moment/locale/sr';
import {formatToLocalDateTime, getColorforStatus, getPriorityName} from "../utils/ViewHelpers"
import myFetch from "../utils/MyFetch";
import IZahtev from "../interfaces/IZahtev";
import IAlert from "../interfaces/IAlert";
import IUser from "../interfaces/IUser";
import IState from "../interfaces/IState";
import IProductionLinePart from "../interfaces/IProductionLinePart";
import ITransition from "../interfaces/ITransition";
import IResponsibleGroup from "../interfaces/IResponsibleGroup";
import ModalSelectTechnician from "../components/ModalSelectTechnician";
import ModalInsertComment from "../components/ModalInsertComment";
import ModalSelectNewGroup from "../components/ModalSelectNewGroup";
import ModalInsertCommentAndPart from "../components/ModalInsertCommentAndPart";
import ModalInsertCommentAndInstalledParts from "../components/ModalInsertCommentAndInstalledParts";
import "../css/Details.css";
import {t} from "../utils/Translation"
import {connect} from "react-redux";
import {loginUser} from "../actions";
import ActionsButton from "../components/ActionsButton";
import Storage from "../utils/Storage";
import IGroup from "../interfaces/IGroup";
import {useGetLanguageCode} from "@ionic/react-hooks/device";
import {useGetLang} from "../hooks/useGetLang";

type DetailsProps = {
    setReloadZadaciNo: any
    reloadZadaciNo: boolean
}

const Details = ( props: DetailsProps ) => {

    let {id} = useParams();
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);
    const [modalSelectUser, setModalSelectUser] = useState({
        showModal: false,
        transition: {
            id: 0,
            process_id: 0,
            current_state: 0,
            next_state: 0,
            action_name: '',
            button_title: '',
            button_type: '',
            button_color: '',
            button_activated: 0,
            additional_input: '',
            automatic_transition: 0,
            timed_transition: 0,
            created_at: '',
            updated_at: ''
        }
    });
    const [modalSelectNewGroup, setModalSelectNewGroup] = useState({
        showModal: false,
        transition: {
            id: 0,
            process_id: 0,
            current_state: 0,
            next_state: 0,
            action_name: '',
            button_title: '',
            button_type: '',
            button_color: '',
            button_activated: 0,
            additional_input: '',
            automatic_transition: 0,
            timed_transition: 0,
            created_at: '',
            updated_at: ''
        }
    });
    const [modalInsertComment, setModalInsertComment] = useState({
        showModal: false,
        transition: {
            id: 0,
            process_id: 0,
            current_state: 0,
            next_state: 0,
            action_name: '',
            button_title: '',
            button_type: '',
            button_activated: 0,
            button_color: '',
            additional_input: '',
            automatic_transition: 0,
            timed_transition: 0,
            created_at: '',
            updated_at: ''
        },
        title: ''
    });
    const [modalInsertCommentAndPart, setModalInsertCommentAndPart] = useState({showModal: false,
        transition: {
            id: 0,
            process_id: 0,
            current_state: 0,
            next_state: 0,
            action_name: '',
            button_title: '',
            button_type: '',
            button_activated: 0,
            button_color: '',
            additional_input: '',
            automatic_transition: 0,
            timed_transition: 0,
            created_at: '',
            updated_at: ''
        }
    });
    const [modalInsertCommentAndInstalledPart, setModalInsertCommentAndInstalledPart] = useState({showModal: false,
        transition: {
            id: 0,
            process_id: 0,
            current_state: 0,
            next_state: 0,
            action_name: '',
            button_title: '',
            button_type: '',
            button_activated: 0,
            button_color: '',
            additional_input: '',
            automatic_transition: 0,
            timed_transition: 0,
            created_at: '',
            updated_at: ''
        }
    });
    const [zahtev, setZahtev] = useState<IZahtev>({
        id: 0,
        company_id: 0,
        process_id: 0,
        user_id: 0,
        ip_v4: '',
        title: '',
        description: '',
        current_state: 0,
        status: '',
        priority: '',
        can_take: false,
        created_at: '',
        updated_at: '',
        links: {
            self: {rel: '', url: ''},
            user: {rel: '', url: ''},
            current_state: {rel: '', url: ''},
            production_line: {rel: '', url: ''},
            production_line_machine: {rel: '', url: ''},
            production_line_part: {rel: '', url: ''},
            responsible_group: {rel: '', url: ''},
            "request-actions": {rel: '', url: ''},
            "executed-actions": {rel: '', url: ''},
            transitions: {rel: '', url: ''}
        },
        request_data: {
            data: {
                production_line_id: 0,
                responsible_group_id: 0,
            }
        },
        request_images: {
            data: []
        }
    });
    const [user, setUser] = useState<IUser>({
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        logged_in: 0,
        created_at: '',
        updated_at: '',
        links: {
            self: {rel: '', url: ''},
            company: {rel: '', url: ''},
            role: {rel: '', url: ''},
            department: {rel: '', url: ''},
        }
    });
    const [responsiblePersons, setResponsiblePersons] = useState<IUser[]>([]);
    const [currentState, setCurrentState] = useState<IState>({
        id: 0,
        name: '',
        description: '',
        created_at: '',
        updated_at: '',
        translations: [],
        links: {
            self: {rel:'', url: ''}
        }
    });
    const [machine, setMachine] = useState<IProductionLinePart>({
        id: 0,
        machine_id: null,
        name: "",
        description: "",
        type: "",
        created_at: "",
        updated_at: "",
        parent_id: 0,
        inventory_number: null,
        links: {
            self: {rel:'', url: ''},
            parent: {rel:'', url: ''},
        }
    });
    const [productionLinePart, setProductionLinePart] = useState<IProductionLinePart>({
        id: 0,
        machine_id: null,
        name: "",
        description: "",
        type: "",
        parent_id: null,
        created_at: "",
        updated_at: "",
        inventory_number: null,
        links: {
            self: {rel:'', url: ''},
            parent: {rel:'', url: ''},
        }
    });
    const [actions, setActions] = useState<ITransition[]>([]);
    const [executedActions, setExecutedActions] = useState<ITransition[]>([]);
    const [responsibleGroup, setResponsibleGroup] = useState<IResponsibleGroup>({
        "id": 0,
        "name": "",
        "description": "",
        "created_at": "",
        "updated_at": "",
        "links": {
            self: {rel: '', url: ''},
        },
        "users": []
    });
    const [dataForSending, setDataForSending] = useState({});
    const [loggedInUser, setLoggedInUser] = useState<IUser>({
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        logged_in: 0,
        created_at: '',
        updated_at: '',
        links: {
            self: {rel: '', url: ''},
            company: {rel: '', url: ''},
            role: {rel: '', url: ''},
            department: {rel: '', url: ''},
        }
    });
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [productionLineParts, setProductionLineParts] = useState<IProductionLinePart[][]>([]);
    const [fabButtonDisabled, setFabButtonDisabled] = useState(false);
    const {lang} = useGetLang()

    useEffect(() => {
        reloadPage(id)
    }, [id]);

    // Removed useIonViewWillEnter() because of problems with cahced page content
    // useIonViewWillEnter(() => {
    //     console.log("from useIonViewWillEnter " + id )
    //     reloadPage()
    // });

    const doRefresh = (event: CustomEvent) => {
        event.detail.complete();
        reloadPage(id)
    }

    const reloadPage = (id:any) => {
        setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/requests/"+id+"?include=responsible_persons,request_images,resolve_user,comments.comment_creator")
            .then(
                (result:any) => {
                    if (result) {
                        setZahtev(result);
                        if (result.responsible_persons) {
                            setResponsiblePersons(result.responsible_persons.data);
                        }
                        Promise.all([
                            myFetch(result['links']['user'].url)
                                .then((data:any) => {setUser(data)}),
                            myFetch(result['links']['current_state'].url)
                                .then((data:any) => {setCurrentState(data)}),
                            myFetch(result['links']['production_line_part'].url)
                                .then((data:any) => {setProductionLinePart(data)}),
                            myFetch(result['links']['production_line_machine'].url)
                                .then((data:any) => {setMachine(data)}),
                            myFetch(result['links']['request-actions'].url)
                                .then((data:any) => {setActions(data.data)}),
                            myFetch(result['links']['executed-actions'].url)
                                .then((data:any) => {setExecutedActions(data.data)})
                        ]).then(() => {
                            setShowLoading(false);
                        }).catch((alert) => {
                            setShowLoading(false);
                            setAlert(alert);
                        });

                        // This one we need later, we dont want to wait for it to hide spinner
                        Promise.all([
                            myFetch(result['links']['responsible_group'].url+'&free_ones=1')
                                .then((data:any) => {setResponsibleGroup(data)}),
                            myFetch(process.env.REACT_APP_API_URL + "/users/"+Storage.get("user_id")+"?include=roles")
                                .then((data:any) => setLoggedInUser(data)),
                            myFetch(process.env.REACT_APP_API_URL + "/groups")
                                .then((data:any) => {
                                    setGroups(data)
                                    setShowLoading(false);
                                }),
                            myFetch(process.env.REACT_APP_API_URL + "/production-lines?parent_id="+result.request_data.data.production_line_id)
                                .then((res:any) => {
                                    setProductionLineParts([...productionLineParts, res]);
                                })
                        ]).catch((alert) => {
                            setShowLoading(false);
                            setAlert(alert);
                        });

                    } else {
                        // Here comes error generated from api. Response is received but error happend on API
                        setAlert({showAlert: true, message: result.error});
                    }
                },
            )
            .catch((data) => {setAlert({showAlert:true, message:data.message})});
    }

    const handleClickOnActionButton = (requestAction: ITransition) => {
        setShowLoading(true);

        // If there are some data to be sent with action, add them here....
        let options = {method: 'POST', body: ''};
        if (Object.keys(dataForSending).length !== 0) {
            options.body = JSON.stringify(dataForSending);
        }

        myFetch(process.env.REACT_APP_API_URL + "/actions/"+requestAction.id, options)
            .then(() => {
                setDataForSending({});
                reloadPage(id);
                props.setReloadZadaciNo(!props.reloadZadaciNo)
                setFabButtonDisabled(false)
            })
            .catch((alertObject) => {
                setShowLoading(false);
                setAlert(alertObject);
            });
    };

    const handleModalOpening = (requestAction: ITransition) => {
        switch (requestAction.additional_input) {
            case 'SelectTechnician':
                setModalSelectUser({showModal: true, transition: requestAction});
                break;
            case 'InsertComment':
                setModalInsertComment({showModal: true, transition: requestAction, title: t('Unos komentara')});
                break;
            case 'InsertCommentTypeClosedReason':
                setModalInsertComment({showModal: true, transition: requestAction, title: t('Unos komentara')});
                setDataForSending({...dataForSending, comment_type: 'closed_reason'})
                break;
            case 'InsertCommentTypeFinish':
                setModalInsertComment({showModal: true, transition: requestAction, title: t('Zatvaranje zahteva')});
                break;
            case 'InsertCommentAndPart':
                setDataForSending({
                    part_title: zahtev.request_data.data.part_title,
                    part_code: zahtev.request_data.data.part_code
                });
                setModalInsertCommentAndPart({showModal: true, transition: requestAction});
                break;
            case 'InsertCommentAndInstalledParts':
                setDataForSending({
                    fix_description: '',
                    resolve_user_id: 0,
                    installedParts: []
                });
                setModalInsertCommentAndInstalledPart({showModal: true, transition: requestAction});
                break;
            case 'SelectNewGroup':
                setModalSelectNewGroup({showModal: true, transition: requestAction});
                break;
            default:
                handleClickOnActionButton(requestAction)
                break;
        }
    }

    let diff = '';
    if (executedActions.length > 0) {
        let mysqlTime =  executedActions[0].updated_at;
        moment.locale(process.env.REACT_APP_LOCALE);
        let then = moment(mysqlTime);
        let current = moment();
        let diffDuration = moment.duration(moment(current, "YYYY-MM-DD HH:mm:ss").diff(moment(then, "YYYY-MM-DD HH:mm:ss"))).asHours()
        if (diffDuration > 24 && diffDuration <= 720) {
            diff = moment.utc(moment(current, "YYYY-MM-DD HH:mm:ss").diff(moment(then, "YYYY-MM-DD HH:mm:ss"))).format("D [dana] HH [časova] mm [minuta]")
        } else if (diffDuration > 720) {
            diff = moment.utc(moment(current, "YYYY-MM-DD HH:mm:ss").diff(moment(then, "YYYY-MM-DD HH:mm:ss"))).format("M [mes.] D [dana] HH [časova] mm [minuta]")
        } else {
            diff = moment.utc(moment(current, "YYYY-MM-DD HH:mm:ss").diff(moment(then, "YYYY-MM-DD HH:mm:ss"))).format("HH [časova] mm [minuta]")
        }

        // diff = moment(then, "YYYY-MM-DD HH:mm:ss").fromNow();
    }

    let itemTimeFromLastAction:JSX.Element|string = '';
    if (zahtev.status !== "denied" && zahtev.status !== "finished") {
        itemTimeFromLastAction = <IonItem>
                                    <IonLabel class="zahtev-item">
                                        <h2>{t("Vreme proteklo od poslednje akcije")}</h2>
                                        <p>{diff}</p>
                                    </IonLabel>
                                </IonItem>;
    }

    let itemBrokenpart:JSX.Element|string = '';
    if (zahtev.request_data.data.part_title || zahtev.request_data.data.part_code) {
        itemBrokenpart = <IonItem>
                            <IonLabel class="zahtev-item">
                                <h2>{t("Deo za nabavku ili zamenu")}</h2>
                                <p>{zahtev.request_data.data.part_title + ' ' + zahtev.request_data.data.part_code}</p>
                            </IonLabel>
                        </IonItem>;
    }

    const ItemVremePopravke = !zahtev.request_data.data.resume_of_operations
        ? ''
        : <IonItem>
            <IonLabel class="zahtev-item">
                <h2>{t("Vreme popravke kvara")}</h2>
                <p>{formatToLocalDateTime(zahtev.request_data.data.resume_of_operations)}</p>
            </IonLabel>
        </IonItem>

    const ItemZahtevResio = () => {
        if (!zahtev.resolve_user?.data) {
            return '';
        }
        return (
            <IonItem>
                <IonLabel class="zahtev-item">
                    <h2>{t("Zahtev rešio")}</h2>
                    <p>{zahtev.resolve_user?.data.first_name} {zahtev.resolve_user?.data.last_name}</p>
                </IonLabel>
            </IonItem>
        )
    }

    const displayCurrentlyResponsiblePerson = () => {
        let name;
        let userIcon;
        if (responsiblePersons.length > 1) {
            name = responsibleGroup.name;
            userIcon = <IonIcon icon={people}/>
        } else if (responsiblePersons.length === 1){
            name = responsiblePersons[0].first_name+' '+responsiblePersons[0].last_name;
            userIcon = <IonIcon icon={person}/>;
        }
        return (
            <p className={"responsible-person"}>
                {userIcon}
                {name}
            </p>
        );
    }

    const picturesButton = zahtev.request_images && zahtev.request_images.data.length > 0
        ? <IonButtons slot="end">
            <IonButton href={"/pictures/"+zahtev.id}>
                <IonIcon color="primary" icon={images}/>
            </IonButton>
          </IonButtons>
        : ''

    const handleClickOnZelimOvajZahtevButton = () => {
        setShowLoading(true);
        myFetch(process.env.REACT_APP_API_URL + "/actions/take-request/"+zahtev.id)
            .then((response) => {
                setShowLoading(false);
                // Ne treba alert jer vec dobijemo toast notifikaciju kad prosledimo sebi
                //setAlert({showAlert: true, message: "Uspešno ste preuzeli zahtev!"})
                // Reload zahtev
                reloadPage(id);
            })
            .catch((errorResponse) => {
                setShowLoading(false);
                setAlert({showAlert: true, message: errorResponse.error});
            });
    }
    const isAdmin = () => {
        if (loggedInUser.roles) {
            let adminRole = loggedInUser.roles?.data.filter((role) => {
                return role.key === "admin";
            })

            if (adminRole && adminRole.length > 0) {
                return true;
            }
        }
        return false;
    }

    const displayActionButtons = () => {
        let userId = Storage.get("user_id");

        if (isAdmin()) {
            return (<ActionsButton actions={actions} onClick={handleModalOpening} disabled={fabButtonDisabled} setDisabled={setFabButtonDisabled}/>)
        }

        if (userId != null) {
            let userIdConverted = parseInt(userId);
            const filtered = responsiblePersons.filter((person) => {
                return person.id === userIdConverted;
            });
            if (filtered.length > 0) {
                return (<ActionsButton actions={actions} onClick={handleModalOpening} disabled={fabButtonDisabled} setDisabled={setFabButtonDisabled}/>)
            }
        }
    }

    const displayComments = () => {
        if (zahtev.comments && zahtev.comments.data.length > 0 ) {
            let comments = zahtev.comments.data.map((comment, index) => {
                return (
                    <IonItem key={index}>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Komentar")}</h2>
                            <p className="multi-line">{comment.comment}</p>
                            <p>
                                <small>
                                    <IonIcon icon={person} />
                                    {' ' + comment.comment_creator?.data.first_name + ' ' + comment.comment_creator?.data.last_name}
                                </small>
                            </p>
                        </IonLabel>
                    </IonItem>
                );
            })
            return comments;
        }
    }

    const displayEditButton = () => {
            return ('')
            // return (
            //     <IonButtons slot="end">
            //         <IonButton href={"/requests/edit/"+zahtev.id}>
            //             <IonIcon color="primary" icon={create} />
            //         </IonButton>F
            //     </IonButtons>
            // )
    }

    const getStateName = () => {
        if (lang !== null && lang !== "sr" && currentState.translations[lang]) {
            return currentState.translations[lang].name
        }

        return currentState.name
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{t("Detalji zahteva")}</IonTitle>
                    {displayEditButton()}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <h2>{t("ID zahteva")}</h2>
                            <p>{zahtev.id}</p>
                        </IonLabel>
                        {picturesButton}
                    </IonItem>
                    <IonItem routerLink={"/requests/details/"+zahtev.id+"/log"} routerDirection="forward" detail={true}>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Status zahteva")}</h2>
                            <IonBadge color={getColorforStatus(zahtev.status)}>{zahtev.status}</IonBadge>
                            <p>{getStateName()}</p>
                            {displayCurrentlyResponsiblePerson()}
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Naslov")}</h2>
                            <p>{zahtev.title}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem routerLink={"/equipment-card/"+machine.id} routerDirection="forward" detail={true}>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Mašina")}</h2>
                            <p>{machine.name}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Pozicija")}</h2>
                            <p>{productionLinePart.name}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonText class="zahtev-item">
                            <h2>{t("Opis problema")}</h2>
                            <p>{zahtev.description}</p>
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonText class="zahtev-item">
                            <h2>{t("Tip kvara")}</h2>
                            <p>{responsibleGroup.name}</p>
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Prioritet")}</h2>
                            <p>{getPriorityName(zahtev.priority)}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Zahtev kreirao")}</h2>
                            <p>{user.first_name} {user.last_name}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <h2>{t("Vreme kreiranja zahteva")}</h2>
                            <p>{formatToLocalDateTime(zahtev.created_at)}</p>
                        </IonLabel>
                    </IonItem>

                    {displayComments()}

                    {ItemZahtevResio()}

                    {ItemVremePopravke}

                    {itemTimeFromLastAction}

                    {itemBrokenpart}

                    <IonItem>
                        <IonLabel class="zahtev-item">
                            <IonButton
                                disabled={!zahtev.can_take}
                                slot="start"
                                color="primary"
                                onClick={handleClickOnZelimOvajZahtevButton}
                            >
                                {t("Ja želim ovaj zahtev")}
                            </IonButton>
                        </IonLabel>
                    </IonItem>

                    {/*<div className={containerClass}>*/}
                    {/*    {actionButtons}*/}
                    {/*</div>*/}

                </IonList>

                {displayActionButtons()}

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent />
                </IonRefresher>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
                <ModalSelectTechnician
                    showModal={modalSelectUser}
                    setShowModal={setModalSelectUser}
                    responsibleGroup={responsibleGroup}
                    dataForSending={dataForSending}
                    setDataForSending={setDataForSending}
                    handleOnModalClose = {handleClickOnActionButton}
                />
                <ModalInsertComment
                    showModal={modalInsertComment}
                    setShowModal={setModalInsertComment}
                    dataForSending={dataForSending}
                    setDataForSending={setDataForSending}
                    handleOnModalClose={handleClickOnActionButton}
                />
                <ModalSelectNewGroup
                    showModal={modalSelectNewGroup}
                    setShowModal={setModalSelectNewGroup}
                    dataForSending={dataForSending}
                    setDataForSending={setDataForSending}
                    handleOnModalClose={handleClickOnActionButton}
                />
                <ModalInsertCommentAndPart
                    showModal={modalInsertCommentAndPart}
                    setShowModal={setModalInsertCommentAndPart}
                    dataForSending={dataForSending}
                    setDataForSending={setDataForSending}
                    handleOnModalClose={handleClickOnActionButton}
                />
                <ModalInsertCommentAndInstalledParts
                    showModal={modalInsertCommentAndInstalledPart}
                    setShowModal={setModalInsertCommentAndInstalledPart}
                    dataForSending={dataForSending}
                    setDataForSending={setDataForSending}
                    handleOnModalClose={handleClickOnActionButton}
                    zahtev={zahtev}
                    groups={groups}
                    productionLineParts={productionLineParts}
                    setProductionLineParts={setProductionLineParts}
                />
            </IonContent>
        </IonPage>
    );
};

// Receives all data from store and filter only states we care about in this component
const mapStateToProps = (state:any) => {
    // key: loggedInUser is prop
    // value: state.loggedInUser is value returned from redux store we care about in this component
    return {
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToProps, {loginUser}) (Details);