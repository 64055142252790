import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage, IonSelect, IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    IonAlert, IonMenuButton, IonButtons, IonLoading,
    IonFab, IonFabButton, IonIcon, IonGrid, IonRow,
    IonCol, IonImg
} from '@ionic/react';
import { camera} from 'ionicons/icons';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import myFetch from "../utils/MyFetch";
import IProductionLinePart from "../interfaces/IProductionLinePart";
import IAlert from "../interfaces/IAlert";
import {createStore} from "redux";
import reducers from "../reducers";
import Storage from "../utils/Storage";
import {setNotificationsNo} from "../actions";
import IGroup from "../interfaces/IGroup";
import {t} from "../utils/Translation"

const PrijavaKvaraPage = (props:any) => {

    const reduxStore = createStore(reducers);
    const company_id = 1;
    const process_id = 1;
    const user_id = localStorage.getItem("user_id");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tipKvara, setTipKvara] = useState('');
    const [priority, setPriority] = useState({value: 'normal', name: t('Srednji')});
    const [productionLineParts, setProductionLineParts] = useState<IProductionLinePart[][]>([]); // Array of array of IProductionLinePart objects
    const [choosenPart, setChoosenPart] = useState(0);
    const [choosenPartLevel, setChoosenPartLevel] = useState(0);
    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});
    const [showLoading, setShowLoading] = useState(false);
    const {photos, takePhoto } = usePhotoGallery();
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [reloadPage, setReloadPage] = useState(false)

    // Initial load of available production lines
    useEffect(() => {
        setShowLoading(true);
        getDataForProductionLine(0, []);
        const userId = Storage.get("user_id")
        if (userId !== null && userId !== undefined) {
            Promise.all([
                myFetch(process.env.REACT_APP_API_URL + "/request-responsibles/count-active/"+userId)
                    .then((data:any) => {reduxStore.dispatch(setNotificationsNo(data))}),
                myFetch(process.env.REACT_APP_API_URL + "/groups")
                    .then((data:any) => {
                        setGroups(data)
                        setShowLoading(false);
                    }),
            ]).catch((error) => {
                setShowLoading(false);
                setAlert({showAlert:true, message:error.message})
            });
        }
    }, [reloadPage]);

    const handleSubmit = () => {
        if (title === '' || description === '' || tipKvara === '' || choosenPart === 0) {
            setAlert({showAlert:true, message: t("Sva polja moraju biti popunjena!"), header: t("Upozorenje")})
            return;
        }

        let request = {
            "company_id": company_id,
            "process_id": process_id,
            "user_id": user_id,
            "title": title,
            "description": description,
            "priority": priority.value,
            "current_state": 1,
            "data": {
                "production_line_id": choosenPart,
                "responsible_group_id": tipKvara
            },
            "photos": undefined

        };

        let photosArray: any = [];
        if (photos.length) {
            photos.forEach((photo) => {
                photosArray.push(photo.base64);
            });
            request.photos = photosArray;
        }

        setSubmitButtonDisabled(true)
        setShowLoading(true)
        myFetch(process.env.REACT_APP_API_URL + "/requests", {method: 'POST', body: JSON.stringify(request)})
            .then((res:any) => {
                if (res.success) {
                    setShowLoading(false);
                    setAlert({showAlert:true, message:t("Zahtev kreiran"), header: t("Uspešna akcija!")});
                    // props.history.goBack(); // ovo sam izbacio, neka ostanu na istoj stranici
                    // reset all input fields values
                    setTitle('')
                    setDescription('')
                    setChoosenPart(0)
                    // setTipKvara() ovo sam disejblovao jer zurim da sredim a ovo govno treba resetovati a sad nemam vremena
                    // tako da ce tip kvara uvek imati vrednost sa prethodnog zahteva, sem ukoliko se stranica ponovo ucita
                    setProductionLineParts([])
                    setSubmitButtonDisabled(false)
                    setReloadPage(!reloadPage)
                    // props.history.goBack();
                } else if (res.error) {
                    setShowLoading(false);
                    setAlert({showAlert:true, message:res.error.message, header: t("Greška")});
                }
            })
            .catch((res) => {
                setShowLoading(false);
                setAlert({showAlert:true, message:t("Greška prilikom kreiranja zahteva!"), header: t("Greška")})}
            );
    };



    const onMachineSelected = (value: string) => {
        setChoosenPart(parseInt(value));
        setChoosenPartLevel(choosenPartLevel + 1)
        // Izbrisati iz arraya komponenti sve komponente ispod

        // Pre nego sto uzme podatke, pronaci ovaj id, u productionLineParts i izbrisati sve ispod
        let needle = 0;
        let selected: IProductionLinePart = {
            id: 0,
            machine_id: null,
            name: '',
            type: '',
            description: '',
            children_count: 0,
            parent_id: 0,
            inventory_number: null,
            created_at: '',
            updated_at: '',
            links: {
                self: { rel: '', url: ''},
                parent: { rel: '', url: ''}
            }
        };
        for (let i=0; i < productionLineParts.length; i++) {
            productionLineParts[i].forEach((part: IProductionLinePart) => {
                if (part.id === parseInt(value)) {
                    needle = i;
                    selected = part;
                }
            });
        }

        // pogledati da li selectovani part ima childrene, ako ima uzimamo ih
        if (selected.children_count && selected.children_count > 0) {
            getDataForProductionLine(parseInt(value), productionLineParts.slice(0, needle + 1));
        }

        // moramo da resetujemo i level na 1 ukoliko je zona izabrana ili promenjena
        if (selected.type === "zone") {
            setChoosenPartLevel(1);
        }
        // setProductionLineParts();
    };

    const onPrioritySelected = ( value: string) => {
        switch (value) {
            case 'low':
                setPriority({value: 'low', name: t('Nizak')});
                break;
            case 'normal':
                setPriority({value: 'normal', name: t('Srednji')});
                break;
            case 'high':
                setPriority({value: 'high', name: t('Visok')});
                break;
        }
    }

    const getDataForProductionLine = (productionLineId: number, slicedProductionLineparts: IProductionLinePart[][]) => {
        myFetch(process.env.REACT_APP_API_URL + "/production-lines?parent_id="+productionLineId)
            .then((res:any) => {
                if (slicedProductionLineparts.length > 0) {
                    setProductionLineParts([...slicedProductionLineparts, res]);
                } else {
                    setProductionLineParts([...productionLineParts, res]);
                }
            })
            .catch((res) => {setAlert({showAlert:true, message:res.message, header: t("Greška")})});
    };

    let dropDownsArray: Array<JSX.Element> = productionLineParts.map((value: IProductionLinePart[], index) => {
        let partType = value[value.length - 1];
        let label:string = "";
        switch (partType.type) {
            case "zone":
                label = t("Proizvodna linija");
                break;
            case "machine":
                label = t("Mašina");
                break;
            case "set":
                label = t("Sklop");
                break;
            case "subset":
                label = t("Podsklop");
                break;
            case "part":
                label = t("Deo");
                break;
        }
        return (
            <Machine key={index} label={label} parts={value} onMachineSelected={onMachineSelected}/>
        );
    });

    let sendButton = () => {
        if (choosenPartLevel >= 2) {
            return (<IonButton expand="block" disabled={submitButtonDisabled} onClick={handleSubmit}>{t("Pošalji")}</IonButton>)
        }
        return '';
    }


    // @ts-ignore
    // @ts-ignore
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t("Prijava kvara")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonItem>
                    <IonLabel position="floating">{t("Naslov")}</IonLabel>
                    <IonInput
                        clearInput
                        inputMode="text"
                        required={true}
                        value={title}
                        onIonChange={(e) => {setTitle((e.target as HTMLInputElement).value)}}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">{t("Opis kvara")}</IonLabel>
                    <IonTextarea
                        rows={3}
                        required
                        value={description}
                        onIonChange={(e) => {setDescription((e.target as HTMLInputElement).value)}}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">{t("Tip kvara")}</IonLabel>
                    <IonSelect cancelText={t("Otkaži")} onIonChange={(e) => {setTipKvara((e.target as HTMLIonSelectElement).value)}}>
                        {groups.map((object, index) => {
                            return (
                                <IonSelectOption key={index} value={object.id}>
                                    {object.name}
                                </IonSelectOption>
                            );
                        })}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">{t("Prioritet")}</IonLabel>
                    <IonSelect selectedText={priority.name} cancelText={t("Otkaži")} onIonChange={(e) => {onPrioritySelected((e.target as HTMLIonSelectElement).value)}}>
                        <IonSelectOption key={3} value='high'>
                            {t("Visok")}
                        </IonSelectOption>
                        <IonSelectOption key={2} value='normal'>
                            {t("Srednji")}
                        </IonSelectOption>
                        <IonSelectOption  key={1} value='low'>
                            {t("Nizak")}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>

                {dropDownsArray}

                <IonGrid>
                    <IonRow>
                        {photos.map((photo, index) => (
                            <IonCol size="6" key={index}>
                                <IonImg src={photo.webviewPath} />
                            </IonCol>
                        ))}
                    </IonRow>
                </IonGrid>

                {sendButton()}

                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="secondary" onClick={() => takePhoto()}>
                        <IonIcon icon={camera} />
                    </IonFabButton>
                </IonFab>

                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={alert.header}
                    subHeader={alert.subheader}
                    message={alert.message}
                    buttons={['OK']}
                />

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />

            </IonContent>
        </IonPage>
    );
};

interface props {
    label: string,
    parts: IProductionLinePart[],
    onMachineSelected: (value: string) => void
}

const Machine: React.FC<props> = ({label, parts, onMachineSelected}) => {

    return(
        <IonItem>
            <IonLabel position="floating">{label}</IonLabel>
            <IonSelect class="drop-down-menu-smaller-font" cancelText={t("Otkaži")} onIonChange={(e) => {onMachineSelected((e.target as HTMLIonSelectElement).value)}}>
                {parts.map((object:IProductionLinePart) => {
                    return (
                        <IonSelectOption class="drop-down-item" key={object.id} value={object.id}>
                            {object.name}
                        </IonSelectOption>
                    );
                })}
            </IonSelect>
        </IonItem>
    );
};


export default PrijavaKvaraPage;
