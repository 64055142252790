type TranslationPair = {
	[key: string]: string;
}

type TranslationLang = {
	[langCode:string]: TranslationPair
}

export const translationsMap: TranslationLang = {
	en: {
		"Otvoreno zahteva": "Open requests",
		"Rešavanje u toku": "In progress...",
		"Nema problema:" : "Everything's good",
		"Nema korisnika na čiju se akciju čeka" : "No users waiting for action",
		"Svi su na raspolaganju" : "Everyone's available",
		"Dashboard" : "Dashboard",
		"Zahteva na čekanju" : "Waiting",
		"Rešeno zahteva" : "Solved",
		"Kvarovi na mašinama" : "Machines issues",
		"Čeka se na akciju od" : "Waiting for action from",
		"Zauzeti" : "Busy",
		"Glavni meni" : "Main menu",
		"Zahtevi" : "Issues",
		"Izveštaji" : "Reports",
		"Ukupno rešenih" : "Total resolved",
		"Vreme rešavanja" : "Resolving time",
		"Radni sati" : "Working hours",
		"Kartoni opreme" : "Equipment cards",
		"Moji podaci" : "My profile",
		"Logout" : "Logout",
		"Greška" : "Error",
		"Učitavanje podataka" : "Data loading",
		"Lista zahteva" : "Issues list",
		"Moji zadaci" : "My tasks",
		"Prijava kvara" : "New issue",
		"MTTR je prosečno vreme od prihvatanja prijave kvara od strane inženjera do statusa finished." : "MTTR is the average time from acceptance of the fault report by the engineer to the finished status.",
		"TTR je prosečeno vreme od vremena preuzimanja kvara od strane tehničara do statusa resolved." : "TTR is the average time from the time the technician takes over the fault to the resolved status.",
		"Datum od" : "Date from",
		"Datum do" : "Date to",
		"Mašina" : "The machine",
		"Odgovorno lice" : "Responsible person",
		"Prioritet" : "Priority",
		"Nizak" : "Low",
		"Srednji" : "Medium",
		"Visok" : "High",
		"MTTRepair izveštaj" : "MTTRepair report",
		"Izaberite" : "Select",
		"Minuta" : "Minutes",
		"Otkaži" : "Cancel",
		"MTTR Recovery izveštaj" : "MTTR Recovery report",
		"Prosečno vreme od kreiranja prijave kvara do statusa 'finished'." : "Average time from creating a fault report to 'finished' status.",
		"Vreme provedeno na rešavanju" : "Time spent on resolving",
		"Ukupno vreme provedeno na rešavanju kvarova, za zadate kriterijume." : "Total time spent on troubleshooting, for the given criteria.",
		"Ukupno rešenih izveštaj" : "Total resolved report",
		"Ukupan broj rešenih zahteva (sa statusom 'finished' ili 'resolved') u zadatom periodu sa zadatim kriterijumima." : "Total number of resolved requests (with the status 'finished' or 'resolved') in a given period with given criteria.",
		"Radnih sati" : "Working hours",
		"Radni sati izveštaj" : "Working hours report",
		"Izveštaj radnih sati" : "Working hours report",
		"Ukupno vreme utrošenih radnih sati za sve kvarove, od kreiranja prijave do statusa 'finished' ili 'resolved', prema zadatim kriterijumima." : "Total time, spent working hours, for all failures, from report creation to 'finished' or 'resolved' status, according to the specified criteria.",
		"Lista mašina" : "Machines list",
		"Karton opreme" : "Equipment card",
		"Opis" : "Description",
		"Inventarski broj" : "Inventory no",
		"Serijski broj" : "Serial no",
		"Proizvođač" : "Manufacturer",
		"Godina proizvodnje" : "Year of production",
		"Datum kvara" : "Issue date",
		"Datum popravke" : "Fix date",
		"Popravio" : "Resolved by",
		"Ugrađen deo" : "Installed part",
		"Detalji zahteva" : "Issue details",
		"ID zahteva" : "Issue ID",
		"Status zahteva" : "Status",
		"Naslov" : "Title",
		"Pozicija" : "Position",
		"Opis problema" : "Issue Description",
		"Tip kvara" : "Issue type",
		"Zahtev kreirao" : "Created by",
		"Vreme kreiranja zahteva" : "Creation time",
		"Vreme proteklo od poslednje akcije" : "Time since last action",
		"Deo za nabavku ili zamenu" : "Part for replacement or procurement",
		"Vreme popravke kvara" : "Issue resolve time",
		"Zahtev rešio" : "Resolved by",
		"Komentar" : "Note",
		"Ja želim ovaj zahtev" : "I want this issue",
		"Prijavi se" : "Log in",
		"Zapamti me" : "Remember me",
		"Lozinka ne može biti prazna" : "Password required",
		"Lozinka i potvrda lozinke moraju biti iste" : "Password and confirmed password must be same",
		"Lozinka i potvrda lozinke moraju imati min 4 karaktera" : "Password length is min 4 characters",
		"Lozinka uspešno kreirana" : "Password successfully created",
		"Uspešna akcija!" : "Success!",
		"Kreirao zahteva" : "Issues created",
		"Rešio zahteva" : "Issues resolved",
		"Nova lozinka" : "New password",
		"Potvrda lozinke" : "Confirm password",
		"Želim notifikacije i offline" : "Receive notifications when offline",
		"Promeni lozinku" : "Change password",
		"Unos komentara" : "Enter comment",
		"Unesite komentar" : "Enter comment",
		"Pošalji" : "Send",
		"Komentar je obavezan!" : "Comment required!",
		"Proizvodna linija" : "Production line",
		"Sklop" : "Set",
		"Podsklop" : "Subset",
		"Deo" : "Part",
		"Unos podataka o popravci" : "Repair details",
		"Unesite opis popravke" : "Enter repair description",
		"Dodaj deo" : "Add part",
		"Upozorenje" : "Warning",
		"Unos podataka o delu" : "Part details",
		"Unesite naziv dela" : "Part title",
		"Unesite šifru dela" : "Part id code",
		"kom" : "pcs",
		"Unesite naziv upotrebljenog dela" : "Enter used part name",
		"Unesite količinu" : "Quantity",
		"Unesite jedinicu mere" : "Unit of measure",
		"Snimi" : "Save",
		"Morate izabrati grupu kojoj prosleđujete zahtev i uneti komentar!" : "You must select a group to forward the request to and enter a comment!",
		"Izaberite novu grupu" : "Select new group",
		"Odgovorna grupa" : "Responsible group",
		"Prosledi" : "Forward",
		"Morate izabrati osobu kojoj prosleđujete zahtev!" : "You must select the person to whom you are forwarding the issue!",
		"Izaberite tehničara" : "Select technician",
		"Odgovorna osoba" : "Responsible person",
		"Izaberite člana grupe" : "Select group member",
		"Otvoren" : "Open",
		"U toku" : "In progress",
		"Rešeno" : "Resolved",
		"Odbijeno" : "Denied",
		"Završeno" : "Finished",
		"Na čekanju" : "Waiting",
		"Sistem" : "System",
		"Filter" : "Filter",
		"Šifra rezervnog dela" : "Spare part id code",
		"Od datuma" : "From date",
		"Do datuma" : "To date",
		"Izaberite datum" : "Select date",
		"Izaberite mašinu" : "Select machine",
		"Tehničar" : "Technician",
		"Ključna reč" : "Search term",
		"Filtriraj" : "Filter",
		"Resetuj filter" : "Reset filter",
		"Vremenska linija" : "Time line",
		"Sva polja moraju biti popunjena": "All fields are required!",
		"Greška prilikom kreiranja zahteva!": "Error during issue creation!",
		"Zahtev kreiran": "Issue created",
		"Opis kvara": "Problem description",
		"Završen": "Finished",
		"Jezik": "Language",
		"Engleski": "English",
		"Srpski": "Serbian",
	},
	de: {
		"Otvoreno zahteva": "Anfragen öffnen",
		"Rešavanje u toku": "In Bearbeitung...",
	}
}