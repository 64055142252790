import React, {useEffect, useState} from 'react';
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/react';
import {t} from "../utils/Translation"

type Props = {
    onPrioritySelected: any,
    resetFlag: boolean
}

const PrioritySelectItem = ({onPrioritySelected, resetFlag}: Props) => {

    const [priority, setPriority] = useState();

    const handleOnPrioritySelected = (value: string) => {
        onPrioritySelected(value)
        setPriority(value)
    }

    useEffect(() => {
        setPriority(undefined)
    }, [resetFlag])

    return (
        <IonItem>
            <IonLabel position="floating">{t("Prioritet")}:</IonLabel>
            <IonSelect value={priority} cancelText={t("Otkaži")} placeholder={t("Izaberite")} onIonChange={(e) => {
                handleOnPrioritySelected((e.target as HTMLIonSelectElement).value)
            }}>
                <IonSelectOption key={1} value="low">
                    {t("Nizak")}
                </IonSelectOption>
                <IonSelectOption key={2} value="normal">
                    {t("Srednji")}
                </IonSelectOption>
                <IonSelectOption key={3} value="high">
                    {t("Visok")}
                </IonSelectOption>
            </IonSelect>
        </IonItem>
    );
};

export default PrioritySelectItem;
