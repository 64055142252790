import React, {useState} from 'react';

import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonRouterOutlet,
    IonIcon,
    IonLabel, IonButton, IonButtons, IonInput, IonSelectOption, IonSelect, IonDatetime
} from '@ionic/react';
import {close} from 'ionicons/icons';
import { menuController } from "@ionic/core";
import '../css/FilterRequestsMenu.css';
import StatusSelectItem from "./StatusSelectItem";
import IProductionLinePart from "../interfaces/IProductionLinePart";
import IRequestsFilter from "../interfaces/IRequestsFilter";
import IUser from "../interfaces/IUser";
import moment from "moment";
import PrioritySelectItem from "./PrioritySelectItem";
import {t} from "../utils/Translation"

type FilterRequestsProps = {
    machines: IProductionLinePart[],
    technicians: IUser[],
    onFilterClicked: any
}

const FilterRequestsMenu = (props: FilterRequestsProps) => {

    const [machineId, setMachineId] = useState();
    const [partCode, setPartCode] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [technician, setTechnician] = useState();
    const [search, setSearch] = useState();
    const [status, setStatus] = useState();
    const [priority, setPriority] = useState();
    const [resetFlag, setResetFlag] = useState(true);

    const onMachineSelected = (value: number) => {
        setMachineId(value)
    }

    const onPartCodeChanged = (value: string) => {
        setPartCode(value)
    }

    const onDateFromSelected = (value: string) => {
        setDateFrom(value)
    }

    const onDateToSelected = (value: string) => {
        setDateTo(value)
    }

    const onTechnicianSelected = (value: number) => {
        setTechnician(value)
    }

    const onSearchChanged = (value: string) => {
        setSearch(value)
    }

    const resetujFilter = () => {
        setMachineId(undefined)
        setPartCode(undefined)
        setDateFrom(undefined)
        setDateTo(undefined)
        setTechnician(undefined)
        setResetFlag(!resetFlag)
        setSearch(undefined)
        setPriority(undefined)
    }

    const submitForm = () => {
        let filter:IRequestsFilter = {}
        if (machineId !== undefined) {
            filter.machine_id = machineId
        }
        if (partCode !== undefined) {
            filter.part_code = partCode
        }
        if (dateFrom !== undefined) {
            filter.created_from = dateFrom
        }
        if (dateTo !== undefined) {
            filter.created_to = dateTo
        }
        if (technician !== undefined) {
            filter.technician = technician
        }
        if (status !== undefined) {
            filter.status = status
        }
        if (search !== undefined) {
            filter.search = search
        }
        if (priority !== undefined) {
            filter.priority = priority
        }
        props.onFilterClicked(filter)
    }

    return (
        <>
            <IonMenu side="end" menuId="filter" contentId="filterMenu">
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>{t("Filter")}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => {menuController.toggle("filter")}}>
                                <IonIcon slot="icon-only" icon={close} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent id="filterMenu">
                    <IonList class="clickable">
                        <IonItem>
                            <IonLabel position="floating">{t("Izaberite mašinu")}</IonLabel>
                            <IonSelect value={machineId} cancelText={t("Otkaži")} onIonChange={(e) => {
                                onMachineSelected((e.target as HTMLIonSelectElement).value)
                            }}>
                                {props.machines.map((object, index) => {
                                    return (
                                        <IonSelectOption key={index} value={object.id}>
                                            {object.name}
                                        </IonSelectOption>
                                    );
                                })}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">{t("Šifra rezervnog dela")}</IonLabel>
                            <IonInput
                                clearInput
                                inputMode="text"
                                required={false}
                                value={partCode}
                                onIonChange={(e) => {onPartCodeChanged((e.target as HTMLInputElement).value)}}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">{t("Od datuma")}</IonLabel>
                            <IonDatetime
                                displayFormat="DD.MM.YYYY."
                                placeholder={t("Izaberite datum")}
                                value={dateFrom}
                                onIonChange={e => {
                                    onDateFromSelected(moment(e.detail.value!).format('YYYY-MM-DD'));
                                }}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">{t("Do datuma")}</IonLabel>
                            <IonDatetime
                                displayFormat="DD.MM.YYYY."
                                placeholder={t("Izaberite datum")}
                                value={dateTo}
                                onIonChange={e => {
                                    onDateToSelected(moment(e.detail.value!).format('YYYY-MM-DD'));
                                }}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">{t("Tehničar")}</IonLabel>
                            <IonSelect value={technician} cancelText={t("Otkaži")} onIonChange={(e) => {onTechnicianSelected((e.target as HTMLIonSelectElement).value)}}>
                                {props.technicians.map((object, index) => {
                                    return (
                                        <IonSelectOption key={index} value={object.id}>
                                            {object.first_name + ' ' + object.last_name}
                                        </IonSelectOption>
                                    );
                                })}
                            </IonSelect>
                        </IonItem>

                        <StatusSelectItem resetFlag={resetFlag} onStatusSelected={setStatus} />

                        <IonItem>
                            <IonLabel position="floating">{t("Ključna reč")}</IonLabel>
                            <IonInput
                                clearInput
                                inputMode="text"
                                required={false}
                                value={search}
                                onIonChange={(e) => {onSearchChanged((e.target as HTMLInputElement).value)}}
                            />
                        </IonItem>

                        <PrioritySelectItem onPrioritySelected={setPriority} resetFlag={resetFlag} />

                        <IonItem lines={"none"}>
                            <IonButton
                                size="default"
                                expand="block"
                                onClick={submitForm}
                            >
                                {t("Filtriraj")}
                            </IonButton>
                            <IonButton
                                type="reset"
                                size="default"
                                expand="block"
                                onClick={resetujFilter}
                            >
                                {t("Resetuj filter")}
                            </IonButton>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>
            <IonRouterOutlet />
        </>
    );
}

export default FilterRequestsMenu;