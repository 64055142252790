import React, {useState} from "react";
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonModal,
    IonLabel,
    IonItem, IonInput
} from "@ionic/react";
import IInstalledPart from "../interfaces/IInstalledPart";
import Storage from "../utils/Storage"
import {t} from "../utils/Translation"

type ModalProps = {
    showModal: boolean,
    setShowModal: any,
    handleOnModalClose: any
}

const ModalInsertPartData = ({showModal, setShowModal, handleOnModalClose}: ModalProps) => {

    // Really??? Can't be simplier? Check this once more....
    let user_id = Storage.get('user_id'); // this is always type of string|null
    if (user_id === null) {
        user_id = "0";
    }

    const [installedPart, setInstalledPart] = useState<IInstalledPart>({
        name: '',
        part_code: '',
        quantity: 1,
        jm: t('kom'),
        user_id: parseInt(user_id)
    });

    return(
        <IonModal
            isOpen={showModal}
            onDidDismiss={()=>{
                // if (Object.keys(dataForSending).length !== 0) {
                //     handleOnModalClose(showModal.transition)
                // }
            }
        }>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("Unos podataka o delu")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite naziv upotrebljenog dela")}</IonLabel>
                    <IonInput
                        value={installedPart?.name}
                        onIonChange={e => setInstalledPart({...installedPart, name: (e.target as HTMLInputElement).value})}
                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite šifru dela")}</IonLabel>
                    <IonInput
                        value={installedPart.part_code}
                        onIonChange={e => setInstalledPart({...installedPart, part_code: (e.target as HTMLInputElement).value})}
                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite količinu")}</IonLabel>
                    <IonInput
                        value={installedPart.quantity.toString()}
                        onIonChange={e => setInstalledPart({...installedPart, quantity: parseFloat((e.target as HTMLInputElement).value)})}
                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite jedinicu mere")}</IonLabel>
                    <IonInput
                        value={installedPart.jm}
                        onIonChange={e => setInstalledPart({...installedPart, jm: (e.target as HTMLInputElement).value})}
                    >
                    </IonInput>
                </IonItem>
                <div className={"action-buttons-cont-one-row"}>
                    <IonButton size={"large"} onClick={() => setShowModal(false)} color="medium">{t("Otkaži")}</IonButton>
                    <IonButton size={"large"} onClick={() => {
                        handleOnModalClose(installedPart)
                        setShowModal(false)
                    }}>{t("Snimi")}</IonButton>
                </div>

            </IonContent>
        </IonModal>
    );
}

export default ModalInsertPartData;