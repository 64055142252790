import {translationsMap} from "./TranslationStrings";
import Storage from "./Storage"

export const t = (string:string|undefined) => {

	let lang: string|null = Storage.get("lang")
	if (lang === "" || lang === undefined || lang === null) {
		lang = "en" // default
	}

	if (string === undefined) {
		string = ""
	}

	if (lang === "sr") {
		return string
	}

	let translatedString = translationsMap[lang][string]
	if (translatedString === "" || translatedString === undefined) {
		return string
	}
	return translatedString
}