import React, {useState} from "react";
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonModal,
    IonLabel,
    IonTextarea,
    IonItem,
    IonInput, IonAlert
} from "@ionic/react";
import ITransition from "../interfaces/ITransition";
import IAlert from "../interfaces/IAlert";
import {t} from "../utils/Translation"

type ModalProps = {
    showModal: {showModal:boolean, transition: ITransition},
    setShowModal: any,
    dataForSending: any,
    setDataForSending: any,
    handleOnModalClose: any
}

const ModalInsertCommentAndPart = ({ showModal, setShowModal, dataForSending, setDataForSending, handleOnModalClose }: ModalProps) => {

    const [alert, setAlert] = useState<IAlert>({showAlert:false, message: ""});

    const handleInput = (value:object) => {
        setDataForSending({...dataForSending, ...value});
    };

    const onSubmit = () => {
        if (dataForSending.comment && dataForSending.comment !== '') {
            setShowModal({showModal: false, transition: showModal.transition})
            handleOnModalClose(showModal.transition)
        } else {
            setAlert({showAlert: true, message: t("Komentar je obavezan!")})
        }
    }

    return(
        <IonModal isOpen={showModal.showModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("Unos podataka o delu")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite komentar")}...</IonLabel>
                    <IonTextarea
                        value={dataForSending.comment}
                        onIonChange={e => handleInput({comment:e.detail.value!})}
                        rows={6}
                    >
                    </IonTextarea>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite naziv dela")}</IonLabel>
                    <IonInput
                        value={dataForSending.part_title}
                        onIonChange={e => handleInput({part_title:(e.target as HTMLInputElement).value})}
                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">{t("Unesite šifru dela")}</IonLabel>
                    <IonInput
                        value={dataForSending.part_code}
                        onIonChange={e => handleInput({part_code:(e.target as HTMLInputElement).value})}
                    >
                    </IonInput>
                </IonItem>
                <div className={"action-buttons-cont-one-row"}>
                    <IonButton size={"large"} onClick={() => onSubmit()}>{t("Pošalji")}</IonButton>
                    <IonButton size={"large"} color="light" onClick={() => setShowModal({showModal: false, transition: showModal.transition})}>{t("Otkaži")}</IonButton>
                </div>
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Upozorenje')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonModal>
    );
}

export default ModalInsertCommentAndPart;