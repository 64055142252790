import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts'
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonContent,
    IonMenuButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonDatetime,
    IonSelect,
    IonSelectOption, IonLoading, IonAlert, IonButton, IonIcon
} from '@ionic/react';
import {download} from "ionicons/icons";
import moment from "moment";
import 'moment/locale/sr';
import myFetch from "../../utils/MyFetch";
import {createQueryString} from "../../utils/Network";
import IAlert from "../../interfaces/IAlert";
import IProductionLinePart from "../../interfaces/IProductionLinePart";
import IUser from "../../interfaces/IUser";
import {t} from "../../utils/Translation"

const getPercentages = (data: {mttr: number, mtr: number}) => {
    let ocekivanoVreme = 3600; // time in seconds in which we expect problem to be solved (one hour)
    return {
        seconds: {...data},
        percents: {
            mttr: Math.round( 100 * data.mttr / ocekivanoVreme),
            mtr: Math.round( 100 * data.mtr / ocekivanoVreme)
        }
    };
}

const convertSecToMinutes = (sec: number) => {
    return Math.round( sec/60 );
};

const MttrReport = (props: any) => {

    moment.locale(process.env.REACT_APP_LOCALE);
    let current = moment();
    let fromDate = moment().subtract(30, 'days');

    const [showLoading, setShowLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>( {showAlert:false, message: ""} );
    const [selectedDateFrom, setSelectedDateFrom] = useState<string>(fromDate.format("YYYY-MM-DD"));
    const [selectedDateTo, setSelectedDateTo] = useState<string>(current.format("YYYY-MM-DD"));
    const [apiExcelUrl, setApiExcelUrl] = useState<string>('');
    const [reportData, setReportData] = useState({seconds: {mttr:0, mtr:0}, percents: {mttr:0, mtr: 0}});
    const [machines, setMachines] = useState<IProductionLinePart[]>([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [priority, setPriority] = useState([]);
    const [chartData, setChartData] = useState({

        series: [
            {
                name: "Mttr",
                data: [0]
            },
            {
                name: "Ttr",
                data: [0]
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 430
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -10,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                },
                formatter: function (val:any, opts:any) {
                    return val + ' min';
                },
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: [''],
                title: {
                    text: t('Minuta'),
                    style: {
                        fontWeight: 100,
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val:any, opts:any) {
                        return val + ' min';
                    }
                }
            }
        }
});


    useEffect(() => {
        setShowLoading(true);
        Promise.all([
            myFetch(process.env.REACT_APP_API_URL + "/production-line-machines")
                .then((data:any) => {setMachines(data)}),
            myFetch(process.env.REACT_APP_API_URL + "/users")
                .then((data:any) => {setUsers(data)}),
            myFetch(process.env.REACT_APP_API_URL + "/reports/mttr")
                .then((data:any) => {
                    let dataWithPercents = getPercentages(data);
                    setChartData({...chartData, series: [{name: "Mttr", data:[convertSecToMinutes(data.mttr)]}, {name: "Ttr", data:[convertSecToMinutes(data.mtr)]}]})
                    // setReportData(dataWithPercents);
                })
        ])
        .then(() => {
            setShowLoading(false);
        })
        .catch((alert) => {
            setShowLoading(false);
            setAlert(alert);
        });

    }, []);

    useEffect(() => {
        setShowLoading(true);
        let queryString = createQueryString({
            date_from: selectedDateFrom,
            date_to: selectedDateTo,
            priority: priority,
            machine_id: selectedMachine,
            person_id: selectedUser,
        })
        let url = process.env.REACT_APP_API_URL + "/reports/mttr?"+queryString;
        let urlExcel = process.env.REACT_APP_API_URL + "/reports/mttr-excel?"+queryString;
        setApiExcelUrl(urlExcel); // so we can use this url for export to excel button
        myFetch(url)
            .then((data:any) => {
                let dataWithPercents = getPercentages(data);
                setChartData({...chartData, series: [{name: "Mttr", data:[convertSecToMinutes(data.mttr)]}, {name: "Ttr", data:[convertSecToMinutes(data.mtr)]}]})
                setReportData(dataWithPercents);
                setShowLoading(false);
            })
            .catch((alert) => {
                setShowLoading(false);
                setAlert(alert);
            });
    }, [selectedDateFrom, selectedDateTo, selectedMachine, priority, selectedUser])

    const machinesSelectOptions = machines.map((machine, index) => {
        return (
            <IonSelectOption key={index} value={machine.id}>
                {machine.name}
            </IonSelectOption>
        );
    })

    const usersSelectOptions = users.map((user, index) => {
        return (
            <IonSelectOption key={index} value={user.id}>
                {user.first_name + ' ' + user.last_name}
            </IonSelectOption>
        );
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t("MTTRepair izveštaj")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>MTTRepair</IonCardTitle>
                        <Chart options={chartData.options} series={chartData.series} type="bar"/>
                        <IonCardSubtitle>Mean Time To Repair</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <p>{t("MTTR je prosečno vreme od prihvatanja prijave kvara od strane inženjera do statusa finished.")}</p>
                        <p>{t("TTR je prosečeno vreme od vremena preuzimanja kvara od strane tehničara do statusa resolved.")}</p>
                        <br/>

                        <IonItem>
                            <IonLabel>{t("Datum od")}:</IonLabel>
                            <IonDatetime displayFormat="DD.MM.YYYY." placeholder="Select Date" value={selectedDateFrom}
                                         onIonChange={e => {
                                             setSelectedDateFrom(moment(e.detail.value!).format('YYYY-MM-DD'));
                                         }}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("Datum do")}:</IonLabel>
                            <IonDatetime displayFormat="DD.MM.YYYY." placeholder="Select Date" value={selectedDateTo}
                                         onIonChange={e => {
                                             setSelectedDateTo(moment(e.detail.value!).format('YYYY-MM-DD'));
                                         }}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("Mašina")}:</IonLabel>
                            <IonSelect cancelText={t("Otkaži")} placeholder={t('Izaberite')}
                                       onIonChange={e => setSelectedMachine((e.target as HTMLIonSelectElement).value)
                            }>
                                {machinesSelectOptions}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("Odgovorno lice")}:</IonLabel>
                            <IonSelect cancelText={t("Otkaži")} placeholder={t('Izaberite')} onIonChange={(e) => {
                                setSelectedUser((e.target as HTMLIonSelectElement).value)
                            }}>
                                {usersSelectOptions}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("Prioritet")}:</IonLabel>
                            <IonSelect cancelText={t("Otkaži")} placeholder={t('Izaberite')} onIonChange={(e) => {
                                setPriority((e.target as HTMLIonSelectElement).value)
                            }}>
                                <IonSelectOption key={1} value="low">
                                    {t("Nizak")}
                                </IonSelectOption>
                                <IonSelectOption key={2} value="normal">
                                    {t("Srednji")}
                                </IonSelectOption>
                                <IonSelectOption key={3} value="high">
                                    {t("Visok")}
                                </IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem lines="none">
                            <IonButton slot="end" size="default" download={undefined} href={apiExcelUrl}>
                                Excel
                                <IonIcon slot="start" icon={download} />
                            </IonButton>
                        </IonItem>

                    </IonCardContent>
                </IonCard>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t("Učitavanje podataka")+"..."}
                    duration={10000}
                />
                <IonAlert
                    isOpen={alert.showAlert}
                    onDidDismiss={() => setAlert({showAlert: false, message: ""})}
                    header={t('Greška')}
                    subHeader={''}
                    message={alert.message}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
};

export default MttrReport;
