/**
 * This file just bootstraps application. Load core libs and Main component
 * and render component into specific document element
 */

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Provider } from 'react-redux';
import { createStore} from "redux";

import App from './App';
import MainMenu from './components/MainMenu';
import reducers from './reducers'

ReactDOM.render(
    <Provider store={createStore(reducers)}>
        <App />
    </Provider>,
    document.getElementById('root')
);

ReactDOM.render(
    <MainMenu/>,
    document.getElementById('menu')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
